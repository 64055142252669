import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class OutletTimingService {
  
  outletsCollection: AngularFirestoreCollection;

  constructor( private fireStore: AngularFirestore, private http: HttpClient) {
    this.outletsCollection = fireStore.collection('outlets');
  }

  getOutletTimings(location: string, locality: string, docId: string) {
    return this.outletsCollection.doc(location).collection(locality).doc(docId).get();
  }

  updateOutletTimings(location: string, locality: string, docId: string, day: string, obj: any): Promise<void> {
    const docRef = this.outletsCollection.doc(location).collection(locality).doc(docId);
    return docRef.get().toPromise().then((doc: any) => {
      if (doc.exists) {
        const outletTimings = doc.data()['outletTimings'] || {};
        outletTimings[day] = obj;
        return docRef.update({ outletTimings });
      } else {
        console.error("Document doesn't exist!");
        return Promise.reject("Document doesn't exist!");
      }
    });
  }
}
