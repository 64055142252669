<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer vw-100 d-flex flex-column" [style.height.px]="screenHeight">
    <div class="titleText d-flex justify-content-between">
        <div>
            Setup your <span class="primaryText">AAhaar</span> outlet quick and easy
        </div>
        <div>
            <p-dropdown *ngIf="outletApplications.length > 0" [options]="outletApplications"
                [(ngModel)]="activeApplicationId" optionLabel="name" placeholder="Select an Application"
                styleClass="drpDownAlignment" (onChange)="handleDropdownChange($event)" />
        </div>
    </div>
    <div class="verticalSeparator"></div>
    <div class="registrationGrid w-100 d-flex">
        <div class="stepperDiv">
            <app-vertical-stepper [steps]="steps" [activeStep]="activeApplication?.currentStep - 1"
                (activeStepChange)="onActiveStepChange($event)"></app-vertical-stepper>
        </div>
        <div class="horizontalSeparator"></div>
        <div class="h-100 w-100 d-flex">
            <div class="stepDetails">
                <div *ngIf="activeApplication?.currentStep == 1">
                    AAharian, welcome aboard! Congratulations on completing your registration. Your journey begins now!
                    We're excited to have you and can't wait to see what you'll achieve.
                </div>
                <div *ngIf="activeApplication?.currentStep == 2">
                    <div class="stepTitle">
                        Tell Us a Bit About Yourself
                    </div>
                    <hr>
                    <form [formGroup]="userDetailsForm">
                        <div class="userDetailsForm d-flex flex-column gap-3">
                            <div class="d-flex flex-column gap-2">
                                <label for="username">First Name</label>
                                <input pInputText id="username" formControlName="firstName" />
                            </div>
                            <div class="d-flex flex-column gap-2">
                                <label for="username">Last Name</label>
                                <input pInputText id="username" formControlName="lastName" />
                            </div>
                            <div class="d-flex flex-column gap-2">
                                <label for="username">Mobile Number</label>
                                <input pInputText id="username" formControlName="mobileNumber" />
                            </div>
                            <div class="d-flex flex-column gap-2">
                                <label for="username">Email</label>
                                <input pInputText id="username" formControlName="email" />
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="activeApplication?.currentStep == 3">
                    <div class="stepTitle">
                        Glad to meet you ! Can we verify your details
                    </div>
                    <hr>
                    <form [formGroup]="userVerificationForm">
                        <div class="userDetailsForm d-flex flex-column gap-3">
                            <div class="d-flex flex-column gap-2">
                                <label for="username">Aadhaar Number</label>
                                <input pInputText id="aadhaarNumber" formControlName="aadhaarNumber"
                                    placeholder="1234 5678 9123" appAadhaarSpacing maxlength="14" />
                            </div>
                            <div class="w-100 d-flex justify-content-end">
                                <p-button label="Send OTP" [link]="true"
                                    [disabled]="!userVerificationForm.get('aadhaarNumber')?.valid"
                                    (click)="initAadhaarOtp()" />
                            </div>
                            <p-dialog header="Verify your Aadhaar" [modal]="true" [(visible)]="showAadhaarModal"
                                [style]="{ width: '30vw' }" [draggable]="false">
                                <span class="p-text-secondary block">Please enter the otp received on Aadhaar registered
                                    mobile number</span>
                                <div class="mt-2">
                                    <p-inputOtp formControlName="aadhaarOtp" [length]="6" />
                                </div>
                                <div class="d-flex justify-content-end gap-2 mt-4">
                                    <p-button class="mr-2" label="Cancel" severity="secondary"
                                        (onClick)="showAadhaarModal = false" />
                                    <p-button label="Save" (onClick)="verifyAadhaarOtp()" />
                                </div>
                            </p-dialog>
                            <div class="d-flex flex-column gap-2">
                                <label for="username">PAN Number</label>
                                <input pInputText id="panNumber" formControlName="panNumber" placeholder="ABCDE1234F"
                                    maxlength="10" appAutoCapitalize />
                            </div>
                            <div class="w-100 d-flex justify-content-end">
                                <p-button label="Send OTP" [link]="true"
                                    [disabled]="!userVerificationForm.get('panNumber')?.valid" (click)="initPanOtp()" />
                            </div>
                            <div class="d-flex flex-column gap-2">
                                <label for="username">Bank Account Number</label>
                                <input pInputText id="bankAccNumber" formControlName="bankAccNumber"
                                    placeholder="12345678" />
                            </div>
                            <div class="d-flex flex-column gap-2">
                                <label for="username">IFSC Code</label>
                                <input pInputText id="bankIfscNumber" formControlName="bankIfscNumber"
                                    placeholder="12345678" />
                            </div>
                            <div class="w-100 d-flex justify-content-end">
                                <p-button label="Verify Bank Account" [link]="true"
                                    [disabled]="!userVerificationForm.get('bankAccNumber')?.valid || !userVerificationForm.get('bankIfscNumber')?.valid"
                                    (click)="initBankVerification()" />
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="activeApplication?.currentStep == 4" class="stepBody">
                    <div class="stepTitle">
                        Awesome !!! Let us discuss about the outlet
                    </div>
                    <hr>
                    <form [formGroup]="outletDetailsForm">
                        <div class="userDetailsForm d-flex flex-column gap-3">
                            <div class="d-flex flex-column gap-2">
                                <label for="outletName">Outlet Name</label>
                                <input pInputText id="outletName" formControlName="outletName" />
                            </div>
                            <div class="d-flex flex-column gap-2">
                                <label for="outletCategory">Outlet Category</label>
                                <p-multiSelect class="w-100" [options]="suggestions" formControlName="outletCategory"
                                    placeholder="Select Cateogries" />
                            </div>
                            <div class="d-flex flex-column gap-2">
                                <div class="d-flex justify-content-between">
                                    <b><label for="outletCategory">Outlet Location</label></b>
                                    <p-button label="Get Current Location" [link]="true" (onClick)="getCurrentLocation()"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="d-flex flex-column gap-2">
                                        <label for="outletName">Longitude</label>
                                        <input pInputText id="outletName" formControlName="outletLongitude" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="d-flex flex-column gap-2">
                                        <label for="outletName">Latitude</label>
                                        <input pInputText id="outletName" formControlName="outletLatitude" />
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="showMap" >
                                <app-google-map
                                [lat]="outletDetailsForm.value.outletLatitude" 
                                [lng]="outletDetailsForm.value.outletLongitude"
                                (locationChanged)="updateLocation($event)">
                                </app-google-map>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="activeApplication?.currentStep == 5" class="stepBody">
                    <div class="stepTitle">
                        Awesome !!! Let us verify your outlet
                    </div>
                    <hr>
                    <p-accordion [activeIndex]="activeApplication?.isGSTSubmitted ? activeApplication?.isFSSAISubmitted ? 2 : 1 : 0" class="verificationAccordion">
                        <p-accordionTab header="GST ( Optional )">
                          <ng-template pTemplate="header" >
                            <div class="w-100 d-flex justify-content-end">
                                <div [ngClass]="{'statusPillCompleted': activeApplication?.isGSTSubmitted, 'statusPillPending': !activeApplication?.isGSTSubmitted}">
                                    {{ activeApplication?.isGSTSubmitted ? 'Completed' : 'Pending' }}
                                </div>
                            </div>
                          </ng-template>
                          <div>
                            <span>
                                <p-fileUpload mode="basic" chooseLabel="Choose" chooseIcon="pi pi-upload"
                                accept="image/*,.pdf" 
                                #gstUpload/>
                            </span>
                            <div class="w-100 mt-2 d-flex justify-content-end">
                                <p-button label="Submit" (onClick)="uploadGst()"/>
                            </div>
                          </div>
                        </p-accordionTab>
                        <p-accordionTab header="Food License">
                          <ng-template pTemplate="header" >
                            <div class="w-100 d-flex justify-content-end">
                                <div [ngClass]="{'statusPillCompleted': activeApplication?.isFSSAISubmitted, 'statusPillPending': !activeApplication?.isFSSAISubmitted}">
                                    {{ activeApplication?.isFSSAISubmitted ? 'Completed' : 'Pending' }}
                                </div>
                            </div>
                          </ng-template>
                          <div>
                            <span>
                                <p-fileUpload mode="basic" chooseLabel="Choose" chooseIcon="pi pi-upload"
                                accept="image/*,.pdf" 
                                #fssaiUpload/>
                            </span>
                            <div class="w-100 mt-2 d-flex justify-content-end">
                                <p-button label="Submit" (onClick)="uploadFSSAI()"/>
                            </div>
                          </div>
                        </p-accordionTab>
                        <p-accordionTab header="Udyam">
                          <ng-template pTemplate="header" >
                            <div class="w-100 d-flex justify-content-end">
                                <!-- <div class="custom-header">GST ( Optional )</div> -->
                                <div [ngClass]="{'statusPillCompleted': activeApplication?.isUdyamSubmitted, 'statusPillPending': !activeApplication?.isUdyamSubmitted}">
                                    {{ activeApplication?.isUdyamSubmitted ? 'Completed' : 'Pending' }}
                                </div>
                            </div>
                          </ng-template>
                          <div>
                            <span>
                                <p-fileUpload mode="basic" chooseLabel="Choose" chooseIcon="pi pi-upload"
                                accept="image/*,.pdf" 
                                #udyamUpload/>
                            </span>
                            <div class="w-100 mt-2 d-flex justify-content-end">
                                <p-button label="Submit" (onClick)="uploadUdyam()"/>
                            </div>
                          </div>
                        </p-accordionTab>
                      </p-accordion>
                </div>
                <div *ngIf="activeApplication?.currentStep == 6" class="stepBody">
                    <div class="stepTitle">
                        Almost there !!! Verify your application
                    </div>
                    <hr>
                    <div class="applicationPreview">

                    </div>
                </div>
                <div *ngIf="activeApplication?.currentStep == 7" class="stepBody">
                    <div class="stepTitle">
                        Sit back and relax
                    </div>
                    <hr>
                    <p>
                        We have received your application. Please wait for the approvals
                    </p>
                </div>
                <div *ngIf="activeApplication?.currentStep != 6" class="bottomBar d-flex justify-content-end align-items-center">
                    <p-button label="Save and Proceed" [disabled]="checkEnable()" (click)="saveCurrentStep()" />
                </div>
                <div *ngIf="activeApplication?.currentStep == 6" class="bottomBar d-flex justify-content-end align-items-center">
                    <p-button label="Pay" (click)="initiatePayment()" />
                </div>
            </div>
            <div class="horizontalSeparator"></div>
            <div class="stepPreview">
                <div *ngIf="activeApplication?.currentStep == 1"
                    class="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div class="stepAsset"></div>
                </div>
                <div *ngIf="activeApplication?.currentStep == 2"
                    class="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div class="stepAsset2"></div>
                </div>
                <div *ngIf="activeApplication?.currentStep == 3"
                    class="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div class="stepAsset3"></div>
                </div>
                <div *ngIf="activeApplication?.currentStep == 4"
                    class="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div class="stepAsset4"></div>
                </div>
                <div *ngIf="activeApplication?.currentStep == 5"
                    class="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div class="stepAsset5"></div>
                </div>
                <div *ngIf="activeApplication?.currentStep == 6"
                    class="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div class="stepAsset6"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Confirm" [modal]="true" [(visible)]="showNewApplicationModal" [style]="{ width: '30vw' }"
    [draggable]="false">
    <span class="p-text-secondary block">You are about to create a new outlet application. Are you sure ?</span>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button class="mr-2" label="Cancel" severity="secondary" (onClick)="toggleNewApplicationModal()" />
        <p-button label="Save" (onClick)="initNewApplication()" />
    </div>
</p-dialog>