import { Component, HostListener } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  constructor(private authService: AuthService) {
    this.getScreenSize();
    if(!this.authService.outletDetails) {
      firebase.auth().onAuthStateChanged((user: any) => {
        if (user) {
          this.authService.getOutletDetails(user.uid).subscribe((res: any) => {
            this.authService.outletDetails = res.data().approvedOutlets['123456'];
            this.authService.userDetails = {
              userName: res.data().firstName +' '+ res.data().lastName,
              uid: res.data().uid
            }
          })
        } else {
          // User not logged in or has just logged out.
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    let safeAreaInsetTop = 0;
    let safeAreaInsetBottom = 0;
    let safeAreaInsetLeft = 0;
    let safeAreaInsetRight = 0;

    if (window.visualViewport) {
      const visualViewport = window.visualViewport;

      safeAreaInsetTop = visualViewport.offsetTop || 0;
      safeAreaInsetBottom = (window.innerHeight - (visualViewport.offsetTop + visualViewport.height)) || 0;

      safeAreaInsetLeft = visualViewport.offsetLeft || 0;
      safeAreaInsetRight = (window.innerWidth - (visualViewport.offsetLeft + visualViewport.width)) || 0;
    }

    if (safeAreaInsetTop > 0 || safeAreaInsetBottom > 0 || safeAreaInsetLeft > 0 || safeAreaInsetRight > 0) {
      this.screenHeight -= (safeAreaInsetTop + safeAreaInsetBottom);
      this.screenWidth -= (safeAreaInsetLeft + safeAreaInsetRight);
    }

    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

}
