import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';

/**
 * IconField wraps an input and an icon.
 * @group Components
 */
const _c0 = ["*"];
class IconField {
  /**
   * Position of the icon.
   * @group Props
   */
  iconPosition = 'left';
  get containerClass() {
    return {
      'p-icon-field-left': this.iconPosition === 'left',
      'p-icon-field-right': this.iconPosition === 'right'
    };
  }
  static ɵfac = function IconField_Factory(ɵt) {
    return new (ɵt || IconField)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: IconField,
    selectors: [["p-iconField"]],
    inputs: {
      iconPosition: "iconPosition"
    },
    ngContentSelectors: _c0,
    decls: 2,
    vars: 1,
    consts: [[1, "p-icon-field", 3, "ngClass"]],
    template: function IconField_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "span", 0);
        i0.ɵɵprojection(1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngClass", ctx.containerClass);
      }
    },
    dependencies: [i1.NgClass],
    styles: ["@layer primeng{.p-icon-field{position:relative}}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconField, [{
    type: Component,
    args: [{
      selector: 'p-iconField',
      template: ` <span class="p-icon-field" [ngClass]="containerClass"><ng-content></ng-content> </span>`,
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: ["@layer primeng{.p-icon-field{position:relative}}\n"]
    }]
  }], null, {
    iconPosition: [{
      type: Input
    }]
  });
})();
class IconFieldModule {
  static ɵfac = function IconFieldModule_Factory(ɵt) {
    return new (ɵt || IconFieldModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: IconFieldModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconFieldModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [IconField, SharedModule],
      declarations: [IconField]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { IconField, IconFieldModule };
