import { Component } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../services/auth.service';
import { OutletTimingService } from '../../services/outlet-timing.service';

@Component({
  selector: 'app-outlet-timings',
  templateUrl: './outlet-timings.component.html',
  styleUrl: './outlet-timings.component.scss'
})
export class OutletTimingsComponent {

  selectedDay: string = '';
  startTime: string = '';
  endTime: string = '';
  user: any;
  outletDetails: any;
  outletTimings: any;
  weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  constructor(private authService: AuthService, private outletTimingsService: OutletTimingService) {
    let startTime = new Date(); // Capture the start time
    const timer = setInterval(() => {
      if (this.authService.outletDetails) {
        clearInterval(timer);
        this.outletDetails = this.authService.outletDetails;
        this.getExistingTimings();
        this.updateSelectedDay('sunday');
      } else {
        // Calculate elapsed time in milliseconds
        let elapsedTime = new Date().getTime() - startTime.getTime(); 
  
        // If elapsed time exceeds 1 minute (60000 milliseconds), clear the interval
        if (elapsedTime > 60000) {
          clearInterval(timer);
          this.updateSelectedDay('sunday');
        }
      }
    },); // Check every 500 milliseconds (adjust as needed)
  }

  getExistingTimings() {
    const docId = this.outletDetails.docId;
    const location = this.outletDetails.location;
    const locality = this.outletDetails.locality;
    this.outletTimingsService.getOutletTimings(location, locality, docId).subscribe((res: any) => {
      this.outletTimings = res.data().outletTimings;
    })
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj).sort((a, b) => {
      return this.weekdays.indexOf(a) - this.weekdays.indexOf(b);
    });
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getPartnerData() {
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        this.user = user;
        this.authService.getOutletDetails(this.user.uid).subscribe((res: any) => {
          res.forEach((data: any) => {
            this.outletDetails = data.data().approvedOutlets['123456'];
          });
        })
      } else {
        // User not logged in or has just logged out.
      }
    });
  }

  getHours() {
    return Array(96).fill(0); // 24 hours * 4 quarters = 96
  }
  
  formatTime(index: number): string {
    const hour = (Math.floor(index / 4) % 12) || 12;  // Get hour in 12-hour format
    const minute = (index % 4) * 15;  // Get the minute
    const period = index < 48 ? 'AM' : 'PM'; // Determine AM/PM based on index
    const formattedHour = hour.toString().padStart(2, '0');
    const formattedMinute = minute.toString().padStart(2, '0');
    return `${formattedHour}:${formattedMinute} ${period}`;
  }

  updateSelectedDay(day: string) {
    var timer = setInterval(() => {
      if(day && this.outletTimings) {
        this.selectedDay = day;
        if (this.outletTimings && this.outletTimings[day]) {
          this.startTime = this.outletTimings[day].startTime;
          this.endTime = this.outletTimings[day].endTime;
        } else {
          // Reset startTime and endTime if no timings found for the selected day
          this.startTime = this.formatTime(0); // Set to default (e.g., 12:00 AM)
          this.endTime = this.formatTime(48); // Set to default (e.g., 12:00 PM)
        }
        clearInterval(timer)
      }
    },);
  }

  updateStartTime(event: any) {
    this.startTime = event.target.value;
  }
  
  updateEndTime(event: any) {
    this.endTime = event.target.value;
  }

  updateOutletTimings() {
          const docId = this.outletDetails.docId;
          const location = this.outletDetails.location;
          const locality = this.outletDetails.locality;

          const obj = { 
            startTime: this.startTime, 
            endTime: this.endTime 
          };

          this.outletTimingsService.updateOutletTimings(location, locality, docId, this.selectedDay, obj)
            .then(() => console.log('Outlet timings updated successfully!'))
            .catch((error: any) => console.error("Error updating outlet timings:", error));
  }

}
