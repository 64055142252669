import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { OrdersService } from '../../services/orders.service';
import firebase from 'firebase/compat/app';
import { ToastService } from '../../shared/toast.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss'
})
export class OrdersComponent {

  outletDetails: any;
  activeOrders: any[] = [];
  newOrders: any[] = [];
  preparingOrders: any[] = [];
  readyOrders: any[] = [];
  pickedUpOrders: any[] = [];
  tabMenuItems: any[] = [];
  activeItem: any;
  value: number = 100;
  countdown: number = 300;
  countdownPercent: number = 100;
  cookingTime: number = 18;
  constructor(private authService: AuthService, private ordersService: OrdersService, private toastService: ToastService) {
    this.tabMenuItems = [
      { label: 'New' },
      { label: 'Preparing' },
      { label: 'Ready' },
      { label: 'Picked Up' },
    ];
    this.activeItem = this.tabMenuItems[0]
    let startTimer = new Date();
    const timer = setInterval(() => {
      if (this.authService.outletDetails) {
        clearInterval(timer);
        this.outletDetails = this.authService.outletDetails;
        console.warn(this.outletDetails);

        this.ordersService.outletsCollection.doc(this.outletDetails.location).collection(this.outletDetails.locality).doc(this.outletDetails.docId).collection('activeOrders').valueChanges({ idField: 'id' }).subscribe((orders: any) => {
          this.activeOrders = orders;
          this.newOrders = this.activeOrders.filter(order => order.orderStatus === 'new');
          this.preparingOrders = this.activeOrders.filter(order => order.orderStatus === 'preparing');
          this.readyOrders = this.activeOrders.filter(order => order.orderStatus === 'ready');
          console.warn(this.readyOrders);
          
          this.pickedUpOrders = this.activeOrders.filter(order => order.orderStatus === 'pickedUp');
          this.newOrders.forEach(order => {
            order.cookingTime = order.minOrderTime + 4 < order.maxOrderTime ? order.minOrderTime + 4 : order.minOrderTime;
            this.initializeCountdown(order);
          });
          this.preparingOrders.forEach(order => {
            this.initializeMarkReadyCountdown(order);
            this.listenToDeliveryPartner(order);
          });
          // this.ordersListLoading = false;
          // if (this.activeOrders.length > previousOrdersLength) {
          //   const audio = new Audio('https://firebasestorage.googleapis.com/v0/b/fruitify-de474.appspot.com/o/sound%2FBell%20Alert.wav?alt=media&token=367fcf16-2701-4662-b0ab-65fc05292d5b');
          //   audio.play();
          // }
          // previousOrdersLength = this.activeOrders.length;
        })
      } else {
        let elapsedTime = new Date().getTime() - startTimer.getTime();
        if (elapsedTime > 300) {
          firebase.auth().onAuthStateChanged((user: any) => {
            if (user) {

              this.authService.getOutletDetails(user.uid).subscribe((res: any) => {
                this.authService.outletDetails = res.data().approvedOutlets['992021'];
                this.authService.userDetails = {
                  userName: res.data().firstName + ' ' + res.data().lastName,
                  uid: res.data().uid
                }
                // this.showSpinner = false;
              })
            } else {
              // User not logged in or has just logged out.
            }
          });
          clearInterval(timer);
        }
      }
    },);
    // let startTime = new Date();
    // const timer = setInterval(() => {
    //   if (this.authService.outletDetails) {
    //     clearInterval(timer);
    //     this.outletDetails = this.authService.outletDetails;
    //     this.ordersService.outletsCollection.doc(this.outletDetails.location).collection(this.outletDetails.locality).doc(this.outletDetails.docId).collection('activeOrders').valueChanges({ idField: 'id' }).subscribe((orders: any) => {
    //       this.activeOrders = orders;
    //       console.warn(this.activeOrders);
    //       this.activeOrders.forEach(order => {
    //         this.initializeCountdown(order); 
    //       });
    //       // this.ordersListLoading = false;
    //       // if (this.activeOrders.length > previousOrdersLength) {
    //       //   const audio = new Audio('https://firebasestorage.googleapis.com/v0/b/fruitify-de474.appspot.com/o/sound%2FBell%20Alert.wav?alt=media&token=367fcf16-2701-4662-b0ab-65fc05292d5b');
    //       //   audio.play();
    //       // }
    //       // previousOrdersLength = this.activeOrders.length;
    //     })
    //   } else {
    //     // Calculate elapsed time in milliseconds
    //     let elapsedTime = new Date().getTime() - startTime.getTime();

    //     // If elapsed time exceeds 1 minute (60000 milliseconds), clear the interval
    //     if (elapsedTime > 60000) {
    //       clearInterval(timer);
    //     }
    //   }
    // },);
  }

  ngOnInit() {
    this.startCountdown();
  }

  initializeCountdown(order: any) {
    const orderPlacedTime = order.orderPlacedAt; // Get order placed time in milliseconds
    const currentTime = Date.now();
    const timeDifference = Math.max(0, orderPlacedTime + 300000 - currentTime); // 300000 milliseconds = 5 minutes
    order.countdown = Math.ceil(timeDifference / 1000); // Countdown in seconds, rounded up
    const interval = setInterval(() => {
      order.countdown--;
      order.countdownPercent = (order.countdown / 300) * 100;
      if (order.countdown <= 0) {
        clearInterval(interval);
        // Perform action when countdown reaches 0, e.g., update order status
      }
    }, 1000);
  }

  listenToDeliveryPartner(order: any) {
    const deliveryPartnerId = order.deliveryPartnerDetails.deliveryPartnerId;

    // Query the deliveryPartnersCollection
    this.ordersService.deliveryPartnersCollection.ref
      .where('deliveryPartnerId', '==', deliveryPartnerId)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          const deliveryPartnerDocRef = querySnapshot.docs[0].ref;

          // Subscribe to valueChanges of the found document
          deliveryPartnerDocRef.onSnapshot(doc => {
            if (doc.exists) {
              const deliveryPartnerData = doc.data();
              console.log('Delivery partner data updated:', deliveryPartnerData);
              // TODO: Update the UI or perform any other actions based on the delivery partner data
            }
          });
        } else {
          console.log('No delivery partner found with id:', deliveryPartnerId);
          // Handle the case where no delivery partner is found
        }
      })
      .catch(error => {
        console.error('Error fetching delivery partner:', error);
        // Handle the error
      });
  }

  initializeMarkReadyCountdown(order: any) {
    const orderAcceptedTime = order.orderAcceptedAt;
    const expectedReadyTime = order.expectedFoodReadyBy;

    const totalTime = expectedReadyTime - orderAcceptedTime;
    order.countdown = Math.ceil(totalTime / 1000);

    // Calculate initial countdown based on current time
    const currentTime = Date.now();
    const initialRemainingTime = Math.max(0, expectedReadyTime - currentTime);
    order.countdown = Math.ceil(initialRemainingTime / 1000);

    const interval = setInterval(() => {
      order.countdown--;
      const remainingTime = Math.max(0, expectedReadyTime - Date.now());
      order.countdownPercent = Math.max(0, (remainingTime / totalTime) * 100);

      if (order.countdown <= 0) {
        clearInterval(interval);
        // Perform action when countdown reaches 0, e.g., update order status
      }

      // Trigger change detection to update the view
    }, 1000);
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedTime = `${minutes.toString().padStart(2,
      '0')}:${remainingSeconds.toString().padStart(2,
        '0')}`;
    return formattedTime;
  }

  onActiveItemChange(event: any) {
    this.activeItem = event;
  }

  calculateTotal(items: any) {
    let total = 0
    items.forEach((item: any) => {
      total = total + (item.itemQuantity * item.itemPrice)
    });
    return total;
  }

  startCountdown() {
    const interval = setInterval(() => {
      this.countdown--;
      this.countdownPercent = (this.countdown / 300) * 100; // Update countdownPercent
      if (this.countdown <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }


  getGradient(percent: number): string {
    return `linear-gradient(to right, #0350B5 ${percent}%, #257aea ${percent}%, #257aea 100%)`;
  }

  decrementCookingTime(order: any) {
    if (order.cookingTime > order.minOrderTime) {
      order.cookingTime--;
    }
  }

  incrementCookingTime(order: any) {
    if (order.cookingTime < order.maxOrderTime) {
      order.cookingTime++;
    }
  }

  acceptOrder(order: any) {
    var obj = {
      outletDetails: {
        outletLocation: this.outletDetails.location,
        outletLocality: this.outletDetails.locality,
        outletId: 'YGQeXWB6K8bZlU7Lz9Dz',
        outletCoordinates: {outletLatitude: '16.719079', outletLongitude: '81.088285'},
        outletName: 'Fruitify'
      },
      
      customerDetails: order.customerDetails,
      orderItems: order.items
    }
    console.warn(this.outletDetails);

    this.ordersService.acceptOrder(order.id, order.cookingTime).then((res) => {
      this.ordersService.newOpenOrder(obj).then((res) => {
        this.toastService.showSuccess("Order accepted successfully")
      })
    })
    this.ordersService.updateUserObj(order.customerDetails.customerId, order.customerDetails.orderId, 'preparing').then((res: any) => {

    }, err => {
      console.warn(err);

    })
  }

  markReady(order: any) {
    this.ordersService.markReady(order.id).then((res) => {
      console.warn(res);
    }, err => {
      console.warn(err);
      
    })
  }

}
