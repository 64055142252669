import { Injectable } from '@angular/core';

declare var Razorpay: any;

@Injectable({
  providedIn: 'root'
})
export class RazorpayService {
  private razorpayKey = 'rzp_live_NOfFb82yJLppYl';
  private razorpaySecret = 'oyWv7iA2wfv53HmGoBBka9v5';

  constructor() { }

  payWithRazorpay(amount: number, key: string, customerDetails: any): Promise<{success: boolean, response?: any}> {
    return new Promise((resolve, reject) => {
      const options = {
        key: key,
        amount: amount.toString(),
        currency: 'INR',
        name: 'AAhaar',
        description: 'One time onboarding fees.',
        display: {
          popup: true
        },
        handler: (response: any) => {
          console.log('Payment success:', response);
          resolve({ success: true, response: response });
        },
        error: (err: any) => {  // Add error callback
          console.error('Payment error:', err);
          reject({ success: false, response: err });
        },
        prefill: {
          name: customerDetails.name,
          email: customerDetails.email,
          contact: customerDetails.mobile,
        },
        theme: {
          color: '#0350B5',
        },
      };
  
      const rzp1 = new Razorpay(options);
  
      rzp1.on('payment.failed', (response: any) => {
        console.error('Payment failed:', response);
        reject({ success: false, response: response });
      });
  
      rzp1.on('payment.captured', (response: any) => {
        console.log('Payment captured:', response);
        resolve({ success: true, response: response });
      });
  
      try {
        rzp1.open();
        console.warn('open');
      } catch (err) {
        console.error('Error opening Razorpay:', err);
        reject({ success: false, response: err });
      }
    });
  }
}
