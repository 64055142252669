<div class="mainContainer">
    <div class="subContainer d-flex flex-column">
        <div class="sortSection d-flex justify-content-between">
            <div class="searchSection d-flex">
                <p-iconField iconPosition="left">
                    <p-inputIcon styleClass="pi pi-search" />
                    <input type="text" pInputText placeholder="Enter Order Id" />
                </p-iconField>
            </div>
            <div class="d-flex gap-2">
                <div class="p-date d-flex">
                    <p-calendar [(ngModel)]="selectedDate" [iconDisplay]="'input'" [showIcon]="true"
                        inputId="icondisplay" />
                </div>
            </div>
        </div>
        <div class="bodySection w-100 h-100 d-flex ">
            <div class="leftPart d-flex flex-column gap-2">
                <div class="metaHistory d-flex flex-column">
                    <div class="cardPart  d-flex justify-content-between">
                        <div class="d-flex flex-column gap-1">
                            <div class="idInfo">Order Id: 235456</div>
                            <div class="byInfo">By Karishma</div>
                            <div class="calenderInfo">Sep 6 | 01:00 PM</div>
                        </div>
                        <div class="chipPart">
                            <span class="chipInfo">Delivered</span>
                        </div>
                    </div>
                    <div class="itemInfo d-flex justify-content-between">
                        <span class="itemMeta">1 x Chicken Sandwich + 1 items</span>
                        <span class="priceInfo">₹ 552</span>
                    </div>
                </div>
            </div>
            <div class="rightPart">
                <div class="historyPanel d-flex flex-column gap-2">
                    <div class="orderDiv d-flex flex-column">
                        <div class="customerDetails d-flex flex-column gap-4">
                            <div class="notifyHeader d-flex gap-2">
                                <span class="headerSpell">Customer Details</span>
                            </div>
                            <div class="orderSection d-flex justify-content-between">
                                <div class="notifyOrder d-flex gap-5">
                                    <div class="orderHeader d-flex flex-column gap-2">
                                        <span class="orderSpell">Order Id</span>
                                        <span class="orderSpell">Ordered Time</span>
                                    </div>
                                    <div class="infoDiv d-flex flex-column gap-2">
                                        <span class="infoSpell"> : 614356</span>
                                        <span class="infoSpell"> : Sep 10 2024, 01:30PM</span>
                                    </div>
                                </div>
                                <div class="notifyOrder d-flex gap-5">
                                    <div class="orderHeader d-flex flex-column gap-2">
                                        <span class="orderSpell">Ordered By</span>
                                        <span class="orderSpell">Address</span>
                                    </div>
                                    <div class="infoDiv d-flex flex-column gap-2">
                                        <span class="infoSpell"> : Shiva</span>
                                        <span class="infoSpell"> : Indira colony ,sanivarapupeta</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="orderDetails d-flex flex-column gap-4">
                            <div class="notifyHeader d-flex gap-2">
                                <span class="headerSpell">Cart Details</span>
                            </div>
                            <div class="d-flex flex-column gap-2">
                                <div class="orderInfo d-flex justify-content-between">
                                    <div class="orderMeta" class="d-flex gap-2">
                                        <span class="itemCount">1 x</span>
                                        <span class="itemName d-flex justify-content-center align-items-center"> Classic
                                            cold
                                            coffee</span>
                                    </div>
                                    <div class="priceInfo">
                                        <span class="priceSpell">₹ 153</span>
                                    </div>
                                </div>
                                <div class="totalDiv d-flex justify-content-between">
                                    <div class="totalCount d-flex gap-2">
                                        <span
                                            class="pi pi-credit-card d-flex justify-content-center align-items-center"></span>
                                        <span class="totalSpell">Total Bill</span>
                                    </div>
                                    <div class="amountSpell">
                                        <span>₹ 153</span>
                                    </div>
                                </div>
                                <div class="instructionDiv ">
                                    <span class="headerSpell">Delivery Instructions : </span>
                                    <span> Don’t Send cutlery,tissues and straws</span>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="deliverDetails d-flex flex-column gap-4">
                            <div class="notifyHeader d-flex gap-2">
                                <span class="headerSpell">Deliver Details</span>
                            </div>
                            <div class="notifyOrder d-flex gap-5">
                                <div class="orderHeader d-flex flex-column gap-2">
                                    <span class="orderSpell">Rider Name</span>
                                    <span class="orderSpell">Delivered Time</span>
                                </div>
                                <div class="infoDiv d-flex flex-column gap-2">
                                    <span class="infoSpell"> : Shiva</span>
                                    <span class="infoSpell"> : 01: 30 PM</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>