<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer vw-100 d-flex flex-column" [style.height.px]="screenHeight"  *ngIf="!showSpinner">
    <div>
        <app-title-bar></app-title-bar>
    </div>
    <div class="d-flex">
        <div class="sideBarDiv">
            <app-side-bar></app-side-bar>
        </div>
        <div class="bodyContainer p-4">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>