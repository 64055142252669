<div class="mainContainer d-flex flex-column" [style.height.px]="screenHeight">
    <div class="topContainer d-flex flex-column">
        <div class="heroContainer d-flex w-100 flex-column align-items-center">
            <div class="imageHolder"></div>
            Welcome to AAhaar! Be an AAhaarian!<br>
            <span class="joinOffer">Join with us at only ₹ 999/-<br>
                (15%) commission for exclusive partners</span>
            <div class="actionContainer">
                <div class="actionSection d-flex">
                    <p-button label="Register your restaurant" (onClick)="navigateToRegister()"/>
                    <p-button label="Login to your account" (onClick)="navigateToLogin()"/>
                </div>
                <div class="helpSection d-flex">
                    <span>Need help? <i class="pi pi-phone" style="font-size: 13px"></i> +91 98765 43210</span>
                    <span><i class="pi pi-envelope" style="font-size: 13px"></i> support&#64;adev.co.in</span>
                </div>
            </div>
            <hr class='w-100'>
            <div class="partnerOverviewSection">
                <div class="featureSection d-flex justify-content-center">
                    <div class="headerText">Why should you partner with AAhaar?<br>
                        <span>Partner with AAhaar, the local food delivery platform that offers low commissions<br>
                            and provides expert accounting services to help grow your business effectively.
                        </span>
                    </div>
                </div>
                <div class="serviceHolder d-flex">
                    <div class="serviceSection">Low commission<br>
                        <div class="serviceAsset"></div>
                        <hr class="w-100">
                        <span class="serviceInfo">Get a commission as low as 15% when you partner with us
                            exclusively.</span>
                    </div>
                    <div class="serviceSection">Scheduled orders<br>
                        <div class="serviceAsset2"></div>
                        <hr class="w-100">
                        <span class="serviceInfo">Scheduled orders enable your restaurant to prepare and plan in
                            advance,
                            ensuring efficient service and reducing last-minute rush during peak hours.</span>
                    </div>
                    <div class="serviceSection">AAhaar feed the need<br>
                        <div class="serviceAsset3"></div>
                        <hr class="w-100">
                        <span class="serviceInfo">Join AAhaar Feed the Need and make a positive impact by donating food
                            to help those in need.Together, we can strengthen communities, reduce waste, and highlight
                            your restaurant's role in addressing hunger.</span>
                    </div>
                </div>
            </div>
            <hr class="w-100">
            <div class="stepsOverview">How it works?
                <div class="stepSection">
                    <div class="profileTag"></div>
                    <div class="stepDescription">Step 1: Register your restaurant<br>
                        <span class="stepDetails">Sign up on the AAhaar portal to list your restaurant and connect with
                            a larger customer base.</span>
                    </div>
                </div>
                <div class="stepSection">
                    <div class="formTag"></div>
                    <div class="stepDescription">Step 2: Enter restaurant details<br>
                        <span class="stepDetails">Provide your restaurant's name, address, menu, and delivery options
                            to complete your listing and prepare for online orders.</span>
                    </div>
                </div>
                <div class="stepSection">
                    <div class="reservationTag"></div>
                    <div class="stepDescription">Step 3: Start receiving orders<br>
                        <span class="stepDetails">After completing your setup, you can start receiving and
                            managing customer orders through the AAhaar platform.</span>
                    </div>
                </div>
            </div>
            <hr class="w-100">
            <div class="card d-flex">
                <span class="faqHeader">Frequently asked questions</span>
                <p-accordion>
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">What documents are required for registering for online
                                    ordering?</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="accordionBody">
                                <p>
                                    Registration for online ordering requires:<br>
                                    <br>
                                    a: FSSAI certificate (application no. if FSSAI is not present)<br>
                                    b: PAN Card<br>
                                    c: GST certificate (if applicable)<br>
                                    d: Udhyam Registration<br>
                                    e: Bank details
                                    <br>
                                </p>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">What are the various costs associated with partnering with
                                    AAhaar?</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="accordionBody">
                                <p>
                                    AAhaar charges a commission fee on each order placed through our platform.
                                    The exact percentage depends on your agreement with AAhaar.
                                    There may also be additional fees for marketing, promotions, or delivery
                                    services,
                                    which
                                    will be clearly outlined in your contract.
                                </p>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">How can I reach AAhaar support if I have inquiries or require
                                    assistance?</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="accordionBody">
                                <p>
                                    You can reach out to AAhaar's support team through the partner
                                    website
                                    <a href="https://qa.staff.aahaar.app/login" target="_blank"
                                        style="text-decoration: none;">Click
                                        here</a>,
                                    email (support&#64;adev.co.in), or by calling
                                    the dedicated partner support hotline (+91 98765 43210).
                                    Our team is available to help you with any queries or issues.
                                </p>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
            <hr class="w-100">
            <div class="supportContainer d-flex flex-column w-100 gap-3">
                <div class="flexWrapper d-flex w-100">
                    <div class="contact">
                        <div class="logoAsset"></div>
                        <div class="buttonSection">
                            <div class="mobileConnect">
                                <span><p-button label="support&#64;adev.co.in" icon="pi pi-envelope" iconPos="left"
                                        [link]="true" /></span>
                            </div>
                            <div class="mailConnect">
                                <span><p-button label="+91 98765 43210" icon="pi pi-phone" iconPos="left"
                                        [link]="true" /></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footerSection">
                    <div class="infoList d-flex flex-column">ABOUT AAhaar
                        <span><a href="https://qa.staff.aahaar.app/login" target="_blank"
                                style="text-decoration: none; color: gray">Who We Are</a></span>
                        <span><a href="https://qa.staff.aahaar.app/login" target="_blank"
                                style="text-decoration: none; color: gray">Work With Us</a></span>
                    </div>
                    <div class="infoList d-flex flex-column">FOR RESTAURANTS
                        <span>Partner With Us</span>
                    </div>
                    <div class="infoList d-flex flex-column">LEARN MORE
                        <span>Privacy</span>
                        <span>Security</span>
                        <span>Terms</span>
                    </div>
                    <div class="socialProfilesHolder d-flex  justify-content-center gap-2">
                        <p-button icon="pi pi-linkedin" [rounded]="true" [outlined]="true" />
                        <p-button icon="pi pi-instagram" [rounded]="true" [outlined]="true" />
                        <p-button icon="pi pi-twitter" [rounded]="true" [outlined]="true" />
                        <p-button icon="pi pi-youtube" [rounded]="true" [outlined]="true" />
                        <p-button icon="pi pi-facebook" [rounded]="true" [outlined]="true" />
                    </div>
                </div>
            </div>
            <hr class="w-100">
            <div class="termsSection">
                By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and
                Content Policies.
                All trademarks are properties of their respective owners. 2008-2024 © AAhaar™ Ltd. All rights reserved.
            </div>
        </div>
    </div>
</div>