import { Component, EventEmitter, Input, Output } from '@angular/core';

interface Step {
  title: string;
  subtitle: string;
}

@Component({
  selector: 'app-vertical-stepper',
  templateUrl: './vertical-stepper.component.html',
  styleUrl: './vertical-stepper.component.scss'
})
export class VerticalStepperComponent {

  @Input() steps: Step[] = [];
  @Input() activeStep: number = 0;
  @Output() activeStepChange = new EventEmitter<number>();

  nextStep() {
    if (this.activeStep < this.steps.length - 1) {
      this.activeStep++;
    }
  }

  previousStep() {
    if (this.activeStep > 0) {
      this.activeStep--;
    }
  }

  // goToStep(index: number) {
  //   if (index === this.activeStep || index < this.activeStep) {
  //     this.activeStep = index;
  //     this.activeStepChange.emit(this.activeStep);
  //   }
  // }
}
