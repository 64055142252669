import { ChangeDetectorRef, Component, Input, ViewChild , HostListener } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AngularFireAuth } from '@angular/fire/compat/auth'; 

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})
export class SideBarComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  items: any[] = [];

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }


  activeIndices: { [key: string]: boolean } = {}; // Store active indices using navItem.name as key
  navItems = [
    {
      name: 'Orders',
      icon: 'pi pi-box',
      route: '/orders',
      isExpandable: false
    },
    {
      name: 'Menu',
      icon: 'pi pi-book',
      route: '/menu',
      isExpandable: false
    },
    {
      name: 'Order History',
      icon: 'pi pi-history',
      route: '/history',
      isExpandable: false
    },
    {
      name: 'Payouts',
      icon: 'pi pi-indian-rupee',
      route: '/payouts',
      isExpandable: false
    },
    // {
    //   name: 'Complaints',
    //   icon: 'pi pi-exclamation-circle',
    //   route: '/complaints',
    //   isExpandable: false
    // },
    // {
    //   name: 'Reviews',
    //   icon: 'pi pi-star',
    //   route: '/review&ratings',
    //   isExpandable: false
    // },
    // {
    //   name: 'Offers',
    //   icon: 'pi pi-tags',
    //   route: '/offers',
    //   isExpandable: false
    // },
    // { name: 'Dashboard', icon: 'pi pi-chart-bar', route: '/dashboard' },
    // { name: 'Settings', icon: 'pi pi-cog', route: '/settings' },
  ];
  @Input() userData: any;
  @ViewChild('profileOverlayPanel') profileOverlayPanel!: OverlayPanel;
  activeIndex: number | null = null;
  activeIndicesArr: number[] = []; 

  constructor(private authService: AuthService, private router: Router, private firebaseAuth: AngularFireAuth,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.activeIndex = this.checkAccordion(); 
    this.items = [
      {
          icon: 'pi pi-sign-out',
          command: () => {
            this.firebaseAuth.signOut().then(() => {
              this.router.navigateByUrl('login'); 
            }).catch(error => {
              // Handle potential logout errors here
              console.error("Error logging out:", error);
            });          }
      },
      {
          icon: 'pi pi-calendar-clock',
          command: () => {
            this.router.navigateByUrl('home/outletTimings'); 
              // this.messageService.add({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
          }
      },
      {
          icon: 'pi pi-user',
          command: () => {
              // this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
          }
      },
  ];
  }

  onTabOpen(navItemName: string) {
    this.activeIndices[navItemName] = true;
  }

  onTabClose(navItemName: string) {
    this.activeIndices[navItemName] = false;
  }

  onClick(navItemName: string) {
    this.activeIndices[navItemName] = false;
  }

  navigateToHome() {

  }


  logout() {
    this.profileOverlayPanel.hide();
    this.firebaseAuth.signOut();
    this.router.navigateByUrl('/login')
  }

  alignOverlayPanel(event: any) {
    setTimeout(() => {
      var ele = this.profileOverlayPanel.container as HTMLElement;
      var leftValue = parseFloat(ele.style.left);
      var topValue = parseFloat(ele.style.top);
      var profileEle = document.getElementsByClassName('profileSection')[0] as HTMLElement
      leftValue = leftValue + profileEle.offsetWidth + profileEle.offsetLeft * 2
      topValue = topValue + profileEle.offsetHeight
      ele.style.left = `${leftValue}px`
      ele.style.top = `${topValue}px`
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  checkAccordion() {
    const currentUrl = window.location.href;
    var returnVal = 0;
    this.navItems.forEach((item, index) => {
      if (currentUrl.includes(item.name.toLowerCase())) {
        returnVal = index
      }
    });
    return returnVal;
  }

  isNavItemActive(navItem: string) {
    const currentUrl = window.location.href;
    if(currentUrl.includes('home/'+this.camelCaseString(navItem.toLowerCase()))) {
      return true;
    } else {
      return false;
    }
  }

  isSubNavItemActive(navItem: string, subNavItem: string) {
    const currentUrl = window.location.href;
    if(currentUrl.includes(navItem.toLowerCase()) && currentUrl.includes(subNavItem.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  updateRoute(mainRoute: any, subRoute?: string) {
    // this.globalDataService.setUserData(this.userData);
    if(subRoute) {
      this.router.navigate(['home', mainRoute.toLowerCase()], {
        queryParams: { action: subRoute?.toLowerCase() }
      });
    } else {
      this.router.navigate(['home', this.camelCaseString(mainRoute.toLowerCase())])
    }
  }

  camelCaseString(str: string): string {
    return str.replace(/\s+([a-z])/g, (match, letter) => letter.toUpperCase())
              .replace(/\s+/g, '');
  }

}
