<div class="mainContainer d-flex justify-content-between w-100">
    <div class="titleSlot d-flex">
        <a href="/home" style="text-decoration: none;">
            <div class="d-flex align-items-baseline brandLogo" style="gap: 3px;">
                <span class="aahaarTitleOblong">AA</span>
                <span class="aahaarTitle">haar</span>
            </div>
        </a>
    </div>
    <div class="titleSection d-flex justify-content-between align-items-center">
        <span>
            {{activeNav}} <span *ngIf="activeAction">| {{activeAction}}</span>
        </span>
        <div class="detailBar d-flex gap-3 align-items-center">
            <div class="notificationIconHolder d-flex  align-items-center">
                <i class="pi pi-bell text-3xl" pBadge value="2"></i>
            </div>
            <div class="switchIcon">
                <p-inputSwitch (onChange)="showConfirmDialog()" [(ngModel)]="isOnline" />
            </div>
            <!-- <div class="logo">
                <img src="../../assets/Screenshot (90).png" alt="../../assets/Screenshot (90).png" height="34px" width="34px">
            </div> -->
            <div class="d-flex  w-100 gap-2">
                <div class="profileIcon">
                    <span class="profileInitials ">{{userDetails?.userName}}</span>
                </div>
                <!-- <span class="DropIcon pi pi-angle-down d-flex justify-content-center align-items-center"></span> -->
            </div>
        </div>
    </div>
</div>
<p-confirmDialog #cd></p-confirmDialog>