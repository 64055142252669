<div class="vertical-stepper">
    <div *ngFor="let step of steps; let i = index" class="step" [class.active]="activeStep === i" [class.completed]="i < activeStep">
        <div class="step-counter">
            <div class="circle">{{ i + 1 }}</div>
            <!-- <div class="circle" (click)="goToStep(i)" [class.clickable]="i === activeStep || i < activeStep">{{ i + 1 }}</div> -->
            <div class="step-info">
                <div class="title d-flex align-items-baseline gap-2">{{ step.title }} <span pTooltip="{{step.subtitle}}" class="pi pi-info-circle"></span></div>
            </div>
        </div>
        <div class="connector" *ngIf="i < steps.length - 1" [class.active]="activeStep > i"></div>
    </div>
</div>