<div class="mainContainer  d-flex h-100">
    <div class="subContainer d-flex ">
        <div class="leftPanel d-flex flex-column">
            <div class="headerSection d-flex justify-content-between align-items-center">
                <span class="mainHeader">Categories</span>
                <span class="addMenuBtn" (click)="openAddCategoryModal()">
                    <span class="pi pi-plus"></span>
                </span>
            </div>
            <div class="emptyCategories d-flex justify-content-center align-items-center" *ngIf="categories.length == 0">
                No Categories
            </div>
            <div *ngIf="categories.length > 0">
                <div cdkDropList (cdkDropListDropped)="drop($event)">
                    <div class="categoryHolder d-flex justify-content-between" *ngFor="let category of categories" cdkDrag (click)="updateSelectedCategory(category)"
               [ngClass]="{ 'activeCategory': category.categoryName == selectedCategory?.categoryName }">
                      <span class="itemName">{{ category.categoryName }}</span> 
                      <span class="toggleSwitch">
                        <p-inputSwitch [(ngModel)]="category.isActive" (onChange)="showConfirmDialog(category)"></p-inputSwitch> 
                      </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="rightPanel d-flex flex-column">
            <div class="addTab d-flex justify-content-between">
                <div class="headerSection d-flex gap-3 align-items-center">
                    <span class="mainHeader">{{selectedCategory?.categoryName}} | Add Items</span>
                    <span class="addMenuBtn" (click)="openAddItemModal()">
                        <span class="pi pi-plus"></span>
                    </span>
                </div>
                <div class="actionIcons d-flex gap-3">
                    <span class="toggleSwitch d-flex gap-3">
                        <div class="vegBtn">
                            <p-inputSwitch [(ngModel)]="filterVeg">
                                <ng-template pTemplate="on">
                                    veg
                                </ng-template>
                                <ng-template pTemplate="off">
                                    off
                                </ng-template>
                              </p-inputSwitch>
                        </div>
                        <div class="nonVegBtn">
                            <p-inputSwitch [(ngModel)]="filterNonVeg" />
                        </div>
                    </span>
                    <span class="pi pi-trash d-flex justify-content-center align-items-center"></span>
                    <span class="pi pi-pen-to-square d-flex justify-content-center align-items-center"></span>
                </div>
            </div>
            <div class="emptyItems d-flex justify-content-center align-items-center" *ngIf="menuItems.length == 0">
                No Items
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="menuItems.length > 0">
                <!-- <div class="togglePart d-flex justify-content-between">
                    <span class="itemName">Burger</span>
                    <div class="actionBtn d-flex gap-3 justify-content-center align-items-center">
                        <span class="toggleSwitch d-flex justify-content-center align-items-center">
                            <p-inputSwitch [(ngModel)]="checked" />
                        </span>
                        <span class="pi pi-pen-to-square"></span>
                    </div>
                </div> -->
                <div class="itemHolder d-flex justify-content-between" *ngFor="let menuItem of menuItems">
                      <span class="itemName">{{ menuItem.itemName }}</span> 
                      <span class="toggleSwitch">
                        <p-inputSwitch [(ngModel)]="menuItem.isActive"></p-inputSwitch> 
                      </span>
                    </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Add New Category" [(visible)]="showAddCategory" [modal]="true" (onHide)="closeAddCategoryModal()"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false" class-="addCategory">
    <form action="submit" [formGroup]="addCategoryForm">
        <div class="row w-50">
            <div class="col">
                <label for="categoryName" class="form-label">Category Name</label>
                <input type="text" formControlName="categoryName" class="form-control">
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button class="addBtn" label="Cancel" [outlined]="true" severity="secondary" size="small"
            (onClick)="closeAddCategoryModal()"></p-button>
        <p-button class="addBtn" label="Save Changes" [disabled]="!addCategoryForm.valid" [outlined]="false"
            severity="primary" size="small" (onClick)="saveCategory()"></p-button>
    </div>
</p-dialog>

<p-dialog header="Add New Item" [(visible)]="showAddItem" [modal]="true" (onHide)="closeAddItemModal()"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <form action="submit" [formGroup]="addItemForm" class="d-flex flex-column gap-3">
        <div class="row">
            <div class="col">
                <label for="itemName" class="form-label">Item Name</label>
                <input type="text" formControlName="itemName" class="form-control" placeholder="Mint Mocktail">
            </div>
            <div class="col">
                <label for="itemClassification" class="form-label">Item Classification</label>
                <p-dropdown [options]="classifications" formControlName="itemClassification" optionLabel="name"
                    placeholder="Select a Classification"></p-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label for="itemDescription" class="form-label">Item Description</label>
                <textarea rows="5" cols="30" pInputTextarea formControlName="itemDescription"
                    placeholder="Item Description : A fresh blend of lime along with......"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label for="itemSellingPrice" class="form-label">Item Price</label>
                <input type="number" formControlName="itemPrice" class="form-control" placeholder="0">
            </div>
        </div>
        <div class="row justify-content-center">
            <label for="itemSellingPrice" class="form-label d-flex justify-content-between">Item Image <span
                    style="cursor: pointer;" *ngIf="imageSrc != ''" (click)="removeImg()">
                    <i class="pi pi-trash"></i>
                </span></label>
            <div class="imgHolder">
                <input *ngIf="imageSrc == ''" type='file' (change)="readURL($event);" />
                <img *ngIf="imageSrc != ''" height="inherit" width="inherit"
                    [src]="imageSrc || 'http://placehold.it/180'" alt="your image" />
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button class="addBtn" label="Cancel" [outlined]="true" severity="secondary" size="small"
            (onClick)="closeAddItemModal()"></p-button>
        <p-button class="addBtn" label="Save Changes" [disabled]="!addItemForm.valid" [outlined]="false"
            severity="primary" size="small" (onClick)="saveItem()"></p-button>
    </div>
</p-dialog>
 
<p-dialog header="Add Items" [modal]="true" [(visible)]="visible" [style]="{ width: '50vw' }" class="addItems">
    <div class="dialogPanel d-flex flex-column gap-3">
        <span class="p-text-secondary block  ">Add new item to this category.</span>
        <div class="timeLine d-flex justify-content-between">
            <div class="SubTimeLine d-flex gap-2">
                <span class="numberCount">1</span>
                <span class="lineName">General</span>
                <span class="pi pi-angle-double-right d-flex justify-content-center align-items-center"></span>
            </div>
            <div class="SubTimeLine d-flex gap-2">
                <span class="numberCircle">2</span>
                <span class="lineName">Pricing</span>
                <span class="pi pi-angle-double-right d-flex justify-content-center align-items-center"></span>
            </div>
            <div class="SubTimeLine d-flex gap-2">
                <span class="numberCircle">3</span>
                <span class="lineName">On Review</span>
                <span class="pi pi-angle-double-right d-flex justify-content-center align-items-center"></span>
            </div>
        </div>
        <div class="formField">
            <div class="itemPanel d-flex gap-5">
                <div class=" d-flex flex-column gap-2">
                    <label for="username" class="font-semibold w-6rem">Name<span class="starMark">*</span></label>
                    <input pInputText id="username" class="flex-auto" autocomplete="off" />
                    <span class="infoText">Provide a concise and clear name for your item</span>
                </div>
                <div class="dropDownSection d-flex flex-column gap-2">
                    <label for="dropdown">Type<span class="starMark">*</span></label>
                    <p-dropdown id="dropdown" [options]="categories" appendTo="body"
                        optionLabel="name" placeholder="Select a Category" />

                </div>
            </div>
            <div class="textAreaField d-flex flex-column gap-2">
                <label for="summary">Description</label>
                <textarea rows="3" cols="30" pInputTextarea></textarea>
                <span class="infoText">Provide a concise and clear description for your item</span>
            </div>
            <div class="uploadField">
                <div class="cardSection">
                    <p-toast />
                    <p-fileUpload mode="basic" chooseLabel="Click to Browse" name="demo[]"
                        url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*" maxFileSize="1000000" />
                </div>
            </div>
            <div class=" btnSection d-flex gap-2">
                <p-button class="draftBtn" label="Save as draft" severity="secondary" (onClick)="visible = false" />
                <p-button class="saveBtn" label="Next Step" (onClick)="visible = false" />
            </div>
        </div>
    </div>

</p-dialog> 