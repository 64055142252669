<div class="mainContainer">
    <div class="navBar"></div>
    <div class="buttonContainer">
        <div class="sidebar"></div>
        <div class="centerButtons">
            <p-button label="New" badge="1" />
            <span><p-button label="Preparing" badge="1" /></span>
            <span><p-button label="Ready" badge="" /></span>
            <span><p-button label="Picked up" badge="" /></span>
        </div>
        <div class="searchButton">
            <p-iconField iconPosition="left">
                <p-inputIcon styleClass="pi pi-search" />
                <input type="text" pInputText placeholder="Search by the id or name" />
            </p-iconField>
        </div>
    </div>
    <div class="cardHolder">
        <div class="emptyDiv"></div>
        <div class="card">
            <div class="leftPanel">
                <div class="header">New order</div>
                <div class="holder">
                    <div class="leftSection">
                        <span>Order id</span>
                        <span>Ordered by</span>
                        <!-- <span>Ordered time</span>
                        <span>Address</span> -->
                    </div>
                    <div class="rightSection">
                        <span>: 1234</span>
                        <span>: Karishma</span>
                        <!-- <span>: Sep 10, 2024 01:30 PM</span>
                        <span>: Sanivarapupeta ( 1km 7 mins away )</span> -->
                    </div>
                </div>
            </div>
            <div class="centerPanel"></div>
            <!-- <div class="rightPanel"></div>
        </div> -->
        </div>
    </div>