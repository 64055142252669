<div class="mainContainer w-100">
    <div class="slotShowCase d-flex flex-column"
        [ngClass]="{'justify-content-center align-items-center': !outletTimings}">
        <div class="d-flex flex-column align-items-center" *ngIf="!outletTimings">
            <span class="emptyTitle">
                Select Slots
            </span>
            <span class="text-muted emptyDescription">
                Manage your outlet hours
            </span>
        </div>
        <div class="d-flex flex-column justify-content-start gap-2" *ngIf="outletTimings">
            <span class="scheduled-timings-title">Scheduled timings</span>
            <hr>
            <div class="slotHolder" *ngFor="let day of objectKeys(outletTimings)">
                <div class=" cardHeader d-flex justify-content-between">
                    <h4 class="day-header">{{ capitalizeFirstLetter(day) }}</h4>
                    <span>
                        <p-button icon="pi pi-times removeSlot" class="remove-button" [rounded]="true" severity="danger"
                            [outlined]="true"></p-button>
                    </span>
                </div>
                <div class="timeInfo">
                    <span class="label-text">Start Time : </span>
                    <span class="time-value">{{ outletTimings[day].startTime }}</span>
                </div>
                <div>
                    <span class="label-text">End Time : </span>
                    <span class="time-value">{{ outletTimings[day].endTime }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="slotScheduler d-flex flex-column p-4">
        <div class="daySelector">
            <div class="dayHolder" (click)="updateSelectedDay('sunday')"
                [ngClass]="{'activeDay': selectedDay === 'sunday'}">Sunday</div>

            <div class="dayHolder" (click)="updateSelectedDay('monday')"
                [ngClass]="{'activeDay': selectedDay === 'monday'}">Monday</div>

            <div class="dayHolder" (click)="updateSelectedDay('tuesday')"
                [ngClass]="{'activeDay': selectedDay === 'tuesday'}">Tuesday</div>

            <div class="dayHolder" (click)="updateSelectedDay('wednesday')"
                [ngClass]="{'activeDay': selectedDay === 'wednesday'}">Wednesday</div>

            <div class="dayHolder" (click)="updateSelectedDay('thursday')"
                [ngClass]="{'activeDay': selectedDay === 'thursday'}">Thursday</div>

            <div class="dayHolder" (click)="updateSelectedDay('friday')"
                [ngClass]="{'activeDay': selectedDay === 'friday'}">Friday</div>

            <div class="dayHolder" (click)="updateSelectedDay('saturday')"
                [ngClass]="{'activeDay': selectedDay === 'saturday'}">Saturday</div>
        </div>
        <div class="slotPicker d-flex justify-content-center align-items-center">
            <div class="slotPicker d-flex flex-column gap-4 justify-content-center align-items-center">
                <div class="slotContainer d-flex">
                    <span>Slot:</span>
                    <select (change)="updateStartTime($event)" [(ngModel)]="startTime">
                        <option *ngFor="let hour of getHours(); let i = index" [value]="formatTime(i)">
                            {{ formatTime(i) }}
                        </option>
                    </select>
                    <select (change)="updateEndTime($event)" [(ngModel)]="endTime">
                        <option *ngFor="let hour of getHours(); let i = index" [value]="formatTime(i)">
                            {{ formatTime(i) }}
                        </option>
                    </select>
                </div>
                <p-toast></p-toast>
                <app-spinner *ngIf="isSaving" class="spinner-overlay"></app-spinner>
                <div class="actionPanel d-flex justify-content-center">
                    <span class="w-100">
                        <button class="custom-primeng-button" (click)="updateOutletTimings()" [disabled]="isSaving">
                            Save
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>