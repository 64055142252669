<div class="mainContainer h-100 w-100">
    <div class="optionNav w-100">
        <!-- <p-tabMenu styleClass="tabMenu" [model]="tabMenuItems" [activeItem]="activeItem"
            (activeItemChange)="onActiveItemChange($event)"></p-tabMenu> -->
            <p-tabMenu styleClass="tabMenu" [model]="tabMenuItems" [activeItem]="activeItem" (activeItemChange)="onActiveItemChange($event)">
                <ng-template pTemplate="header" let-item> 
                <a class="p-ripple p-element p-menuitem-link tabTitle">
                    {{ item.label }}
                    <span class="tab-count" *ngIf="item.label === 'New' && newOrders.length > 0">({{ newOrders.length }})</span>
                    <span class="tab-count" *ngIf="item.label === 'Preparing' && preparingOrders.length > 0">({{ preparingOrders.length }})</span>
                    <span class="tab-count" *ngIf="item.label === 'Ready' && readyOrders.length > 0">({{ readyOrders.length }})</span>
                    <span class="tab-count" *ngIf="item.label === 'Picked Up' && pickedUpOrders.length > 0">({{ pickedUpOrders.length }})</span>
                </a>
                </ng-template>
              </p-tabMenu>
    </div>
    <div class="bodyContent">
        <div class="cardHolder" *ngIf="activeItem!.label == 'New'">
            <div class="orderDiv d-flex" *ngFor="let order of newOrders">
                <!-- <div class="customerDetails d-flex flex-column">
                    <span>New Order</span>
                    <span>Order id :{{order.customerDetails.orderId}}</span>
                    <span>Customer Name :{{order.customerDetails.customerName}}</span>
                </div> -->
                <div class="customerDetails d-flex flex-column gap-4">
                    <div class="notifyHeader d-flex gap-2">
                        <span class="headerSpell">New Order</span>
                        <!-- <img src="../../assets/Screenshot (90).png" alt="../../assets/Screenshot (90).png"> -->
                    </div>
                    <div class="notifyOrder d-flex gap-5">
                        <div class="orderHeader d-flex flex-column gap-2">
                            <span class="orderSpell">Order Id</span>
                            <span class="orderSpell">Ordered By</span>
                            <span class="orderSpell">Order Placed At</span>
                        </div>
                        <div class="infoDiv d-flex flex-column gap-2">
                            <span class="infoSpell"> : {{ order.customerDetails.orderId.slice(-4) }}</span>
                            <span class="infoSpell"> : {{order.customerDetails.customerName}}</span>
                            <span class="infoSpell"> : {{ order.orderPlacedAt | date:'dd MMM yyyy, hh:mm a' }}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="orderDetails">
                    <div *ngFor="let item of order.items" class="d-flex gap-4">
                        <span>
                            {{item.itemQuantity + ' x ' + item.itemName}}
                        </span>
                        <span>
                            ₹ {{item.itemQuantity * item.itemPrice}}
                        </span>
                    </div>
                    <div class="totalBill">
                        Total Bill {{ calculateTotal(order.items) }}
                    </div>
                </div> -->
                <div class="orderDetails d-flex flex-column gap-2">
                    <div class="instructionDiv d-flex gap-2 justify-content-center align-items-center">
                        <span
                            class="pi pi-exclamation-triangle d-flex justify-content-center align-items-center"></span>
                        <span class="instructionSpell">Don’t send cutlery, tissues and straws</span>
                    </div>
                    <div class="orderInfo d-flex justify-content-between" *ngFor="let item of order.items">
                        <div class="orderMeta" class="d-flex gap-2">
                            <span class="itemCount">{{item.itemQuantity + 'x'}}</span>
                            <span
                                class="itemName d-flex justify-content-center align-items-center">{{item.itemName}}</span>
                        </div>
                        <div class="priceInfo">
                            <span class="priceSpell">₹ {{item.itemQuantity * item.itemPrice}}</span>
                        </div>
                    </div>
                    <div class="totalDiv d-flex justify-content-between">
                        <div class="totalCount d-flex gap-2">
                            <span class="pi pi-credit-card d-flex justify-content-center align-items-center"></span>
                            <span class="totalSpell">Total Bill</span>
                        </div>
                        <div class="amountSpell">
                            <span>₹ {{ calculateTotal(order.items) }}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="actionDetails">
                    <p-knob [(ngModel)]="countdownPercent" [showValue]="false" [readonly]="true" ></p-knob>

                    <span class="timerDiv">{{ formatTime(countdown) }} </span>
                </div> -->
                <div class="actionDetails d-flex flex-column gap-3">
                    <div class="adjustDiv d-flex justify-content-evenly">
                        <div class="iconDiv d-flex justify-content-center align-items-center">
                            <p-button icon="pi pi-minus" [rounded]="true" [text]="true" class="minusBtn"
                                (onClick)="decrementCookingTime(order)"></p-button>
                        </div>
                        <div class="knobDiv">
                            <p-knob [(ngModel)]="order.cookingTime" [min]="order.minOrderTime" [max]="order.maxOrderTime"
                                [step]="1" [valueColor]="'#0350b5'" [valueTemplate]="order.cookingTime +'mins'"
                                [showValue]="true" [readonly]="false">
                            </p-knob>
                        </div>
                        <div class="iconDiv d-flex justify-content-center align-items-center">
                            <p-button icon="pi pi-plus" [rounded]="true" [text]="true" class="plusBtn"
                                (onClick)="incrementCookingTime(order)"></p-button>
                        </div>
                    </div>
                    <div class="btnSection d-flex justify-content-evenly">
                        <p-button label="Reject" severity="danger" />
                        <div class="acceptButton" (click)="acceptOrder(order)" [style.background]="getGradient(order.countdownPercent)">
                            Accept in {{ formatTime(order.countdown) }} mins
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cardHolder" *ngIf="activeItem!.label == 'Preparing'">
            <div class="orderDiv d-flex" *ngFor="let order of preparingOrders">
                <!-- <div class="customerDetails d-flex flex-column">
                    <span>New Order</span>
                    <span>Order id :{{order.customerDetails.orderId}}</span>
                    <span>Customer Name :{{order.customerDetails.customerName}}</span>
                </div> -->
                <div class="customerDetails d-flex flex-column gap-4">
                    <div class="notifyHeader d-flex gap-2">
                        <span class="headerSpell">New Order</span>
                        <!-- <img src="../../assets/Screenshot (90).png" alt="../../assets/Screenshot (90).png"> -->
                    </div>
                    <div class="notifyOrder d-flex gap-5">
                        <div class="orderHeader d-flex flex-column gap-2">
                            <span class="orderSpell">Order Id</span>
                            <span class="orderSpell">Ordered By</span>
                            <span class="orderSpell">Order Placed At</span>
                        </div>
                        <div class="infoDiv d-flex flex-column gap-2">
                            <span class="infoSpell"> : {{ order.customerDetails.orderId.slice(-4) }}</span>
                            <span class="infoSpell"> : {{order.customerDetails.customerName}}</span>
                            <span class="infoSpell"> : {{ order.orderPlacedAt | date:'dd MMM yyyy, hh:mm a' }}</span>
                        </div>
                    </div>
                </div>
                <div class="orderDetails d-flex flex-column gap-2">
                    <div class="instructionDiv d-flex gap-2 justify-content-center align-items-center">
                        <span
                            class="pi pi-exclamation-triangle d-flex justify-content-center align-items-center"></span>
                        <span class="instructionSpell">Don’t send cutlery, tissues and straws</span>
                    </div>
                    <div class="orderInfo d-flex justify-content-between" *ngFor="let item of order.items">
                        <div class="orderMeta" class="d-flex gap-2">
                            <span class="itemCount">{{item.itemQuantity + 'x'}}</span>
                            <span
                                class="itemName d-flex justify-content-center align-items-center">{{item.itemName}}</span>
                        </div>
                        <div class="priceInfo">
                            <span class="priceSpell">₹ {{item.itemQuantity * item.itemPrice}}</span>
                        </div>
                    </div>
                    <div class="totalDiv d-flex justify-content-between">
                        <div class="totalCount d-flex gap-2">
                            <span class="pi pi-credit-card d-flex justify-content-center align-items-center"></span>
                            <span class="totalSpell">Total Bill</span>
                        </div>
                        <div class="amountSpell">
                            <span>₹ {{ calculateTotal(order.items) }}</span>
                        </div>
                    </div>
                    <div class="w-100 d-flex flex-column justify-content-center align-items-center">
                        <div class="knobDivReady">
                            <p-knob 
                            [(ngModel)]="order.countdownPercent" 
                            [min]="0"  
                            [max]="100" 
                            [step]="1"
                            [valueColor]="'#0350b5'" 
                            [valueTemplate]="formatTime(order.countdown) + ' mins'"
                            [showValue]="true"
                            [readonly]="true">
                            </p-knob>
                        </div>
                        <div class="btnSection d-flex justify-content-evenly">
                            <div class="acceptButton readyBtn" (click)="markReady(order)" [style.background]="getGradient(order.countdownPercent)">
                                Mark Ready in {{ formatTime(order.countdown) }} mins
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="actionDetails">
                    <p-knob [(ngModel)]="countdownPercent" [showValue]="false" [readonly]="true" ></p-knob>

                    <span class="timerDiv">{{ formatTime(countdown) }} </span>
                </div> -->
                <div class="actionDetails d-flex flex-column gap-4">
                    <div class="notifyHeader d-flex gap-2">
                        <span class="headerSpell">Delivery Partner Details</span>
                        <!-- <img src="../../assets/Screenshot (90).png" alt="../../assets/Screenshot (90).png"> -->
                    </div>
                    <div class="notifyOrder d-flex gap-4" *ngIf="order.deliveryPartnerDetails">
                        <div class="orderHeader d-flex flex-column gap-2">
                            <span class="orderSpell">Name</span>
                            <span class="orderSpell">Mobile Number</span>
                            <span class="orderSpell">Status</span>
                        </div>
                        <div class="infoDiv d-flex flex-column gap-2">
                            <span class="infoSpell"> : {{order.deliveryPartnerDetails.basicDetails.firstName + ' ' + order.deliveryPartnerDetails.basicDetails.lastName}}</span>
                            <span class="infoSpell"> : +91 {{order.deliveryPartnerDetails.basicDetails.mobileNumber}}</span>
                            <span>
                                <div *ngIf="order.isReachedRestaurant">
                                    : Delivery Partner has reached the outlet and is waiting.
                                </div>
                                <div *ngIf="!order.isReachedRestaurant">
                                    : Delivery Partner is on their way to the outlet.
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="notifyOrder d-flex gap-5" *ngIf="!order.deliveryPartnerDetails">
                        <div class="orderHeader d-flex flex-column gap-2">
                            <span class="orderSpell">Delivery partner not assigned</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cardHolder" *ngIf="activeItem!.label == 'Ready'">
            <div class="orderDiv d-flex" *ngFor="let order of readyOrders">
                <!-- <div class="customerDetails d-flex flex-column">
                    <span>New Order</span>
                    <span>Order id :{{order.customerDetails.orderId}}</span>
                    <span>Customer Name :{{order.customerDetails.customerName}}</span>
                </div> -->
                <div class="customerDetails d-flex flex-column gap-4">
                    <div class="notifyHeader d-flex gap-2">
                        <span class="headerSpell">New Order</span>
                        <!-- <img src="../../assets/Screenshot (90).png" alt="../../assets/Screenshot (90).png"> -->
                    </div>
                    <div class="notifyOrder d-flex gap-5">
                        <div class="orderHeader d-flex flex-column gap-2">
                            <span class="orderSpell">Order Id</span>
                            <span class="orderSpell">Ordered By</span>
                            <span class="orderSpell">Order Placed At</span>
                        </div>
                        <div class="infoDiv d-flex flex-column gap-2">
                            <span class="infoSpell"> : {{ order.customerDetails.orderId.slice(-4) }}</span>
                            <span class="infoSpell"> : {{order.customerDetails.customerName}}</span>
                            <span class="infoSpell"> : {{ order.orderPlacedAt | date:'dd MMM yyyy, hh:mm a' }}</span>
                        </div>
                    </div>
                </div>
                <div class="orderDetails d-flex flex-column gap-2">
                    <div class="instructionDiv d-flex gap-2 justify-content-center align-items-center">
                        <span
                            class="pi pi-exclamation-triangle d-flex justify-content-center align-items-center"></span>
                        <span class="instructionSpell">Don’t send cutlery, tissues and straws</span>
                    </div>
                    <div class="orderInfo d-flex justify-content-between" *ngFor="let item of order.items">
                        <div class="orderMeta" class="d-flex gap-2">
                            <span class="itemCount">{{item.itemQuantity + 'x'}}</span>
                            <span
                                class="itemName d-flex justify-content-center align-items-center">{{item.itemName}}</span>
                        </div>
                        <div class="priceInfo">
                            <span class="priceSpell">₹ {{item.itemQuantity * item.itemPrice}}</span>
                        </div>
                    </div>
                    <div class="totalDiv d-flex justify-content-between">
                        <div class="totalCount d-flex gap-2">
                            <span class="pi pi-credit-card d-flex justify-content-center align-items-center"></span>
                            <span class="totalSpell">Total Bill</span>
                        </div>
                        <div class="amountSpell">
                            <span>₹ {{ calculateTotal(order.items) }}</span>
                        </div>
                    </div>
                    <!-- <div class="w-100 d-flex flex-column justify-content-center align-items-center">
                        <div class="knobDivReady">
                            <p-knob 
                            [(ngModel)]="order.countdownPercent" 
                            [min]="0"  
                            [max]="100" 
                            [step]="1"
                            [valueColor]="'#0350b5'" 
                            [valueTemplate]="formatTime(order.countdown) + ' mins'"
                            [showValue]="true"
                            [readonly]="true">
                            </p-knob>
                        </div>
                        <div class="btnSection d-flex justify-content-evenly">
                            <div class="acceptButton readyBtn" (click)="markReady(order)" [style.background]="getGradient(order.countdownPercent)">
                                Mark Ready in {{ formatTime(order.countdown) }} mins
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="actionDetails">
                    <p-knob [(ngModel)]="countdownPercent" [showValue]="false" [readonly]="true" ></p-knob>

                    <span class="timerDiv">{{ formatTime(countdown) }} </span>
                </div> -->
                <div class="actionDetails d-flex flex-column gap-4">
                    <div class="notifyHeader d-flex gap-2">
                        <span class="headerSpell">Delivery Partner Details</span>
                        <!-- <img src="../../assets/Screenshot (90).png" alt="../../assets/Screenshot (90).png"> -->
                    </div>
                    <div class="notifyOrder d-flex gap-4" *ngIf="order.deliveryPartnerDetails">
                        <div class="orderHeader d-flex flex-column gap-2">
                            <span class="orderSpell">Name</span>
                            <span class="orderSpell">Mobile Number</span>
                            <span class="orderSpell">Status</span>
                        </div>
                        <div class="infoDiv d-flex flex-column gap-2">
                            <span class="infoSpell"> : {{order.deliveryPartnerDetails.basicDetails.firstName + ' ' + order.deliveryPartnerDetails.basicDetails.lastName}}</span>
                            <span class="infoSpell"> : +91 {{order.deliveryPartnerDetails.basicDetails.mobileNumber}}</span>
                            <span>
                                <div *ngIf="order.isPickedUp">
                                    : Delivery Partner has picked up the order and is out for delivery.
                                </div>
                                <div *ngIf="!order.isPickedUp">
                                    : Delivery Partner is yet to pick up the order.
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="notifyOrder d-flex gap-5" *ngIf="!order.deliveryPartnerDetails">
                        <div class="orderHeader d-flex flex-column gap-2">
                            <span class="orderSpell">Delivery partner not assigned</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cardHolder" *ngIf="activeItem!.label == 'Picked Up'">
            <div class="orderDiv d-flex" *ngFor="let order of pickedUpOrders">
                <!-- <div class="customerDetails d-flex flex-column">
                    <span>New Order</span>
                    <span>Order id :{{order.customerDetails.orderId}}</span>
                    <span>Customer Name :{{order.customerDetails.customerName}}</span>
                </div> -->
                <div class="customerDetails d-flex flex-column gap-4">
                    <div class="notifyHeader d-flex gap-2">
                        <span class="headerSpell">New Order</span>
                        <!-- <img src="../../assets/Screenshot (90).png" alt="../../assets/Screenshot (90).png"> -->
                    </div>
                    <div class="notifyOrder d-flex gap-5">
                        <div class="orderHeader d-flex flex-column gap-2">
                            <span class="orderSpell">Order Id</span>
                            <span class="orderSpell">Ordered By</span>
                            <span class="orderSpell">Order Placed At</span>
                        </div>
                        <div class="infoDiv d-flex flex-column gap-2">
                            <span class="infoSpell"> : {{ order.customerDetails.orderId.slice(-4) }}</span>
                            <span class="infoSpell"> : {{order.customerDetails.customerName}}</span>
                            <span class="infoSpell"> : {{ order.orderPlacedAt | date:'dd MMM yyyy, hh:mm a' }}</span>
                        </div>
                    </div>
                </div>
                <div class="orderDetails d-flex flex-column gap-2">
                    <div class="instructionDiv d-flex gap-2 justify-content-center align-items-center">
                        <span
                            class="pi pi-exclamation-triangle d-flex justify-content-center align-items-center"></span>
                        <span class="instructionSpell">Don’t send cutlery, tissues and straws</span>
                    </div>
                    <div class="orderInfo d-flex justify-content-between" *ngFor="let item of order.items">
                        <div class="orderMeta" class="d-flex gap-2">
                            <span class="itemCount">{{item.itemQuantity + 'x'}}</span>
                            <span
                                class="itemName d-flex justify-content-center align-items-center">{{item.itemName}}</span>
                        </div>
                        <div class="priceInfo">
                            <span class="priceSpell">₹ {{item.itemQuantity * item.itemPrice}}</span>
                        </div>
                    </div>
                    <div class="totalDiv d-flex justify-content-between">
                        <div class="totalCount d-flex gap-2">
                            <span class="pi pi-credit-card d-flex justify-content-center align-items-center"></span>
                            <span class="totalSpell">Total Bill</span>
                        </div>
                        <div class="amountSpell">
                            <span>₹ {{ calculateTotal(order.items) }}</span>
                        </div>
                    </div>
                    <!-- <div class="w-100 d-flex flex-column justify-content-center align-items-center">
                        <div class="knobDivReady">
                            <p-knob 
                            [(ngModel)]="order.countdownPercent" 
                            [min]="0"  
                            [max]="100" 
                            [step]="1"
                            [valueColor]="'#0350b5'" 
                            [valueTemplate]="formatTime(order.countdown) + ' mins'"
                            [showValue]="true"
                            [readonly]="true">
                            </p-knob>
                        </div>
                        <div class="btnSection d-flex justify-content-evenly">
                            <div class="acceptButton readyBtn" (click)="markReady(order)" [style.background]="getGradient(order.countdownPercent)">
                                Mark Ready in {{ formatTime(order.countdown) }} mins
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="actionDetails">
                    <p-knob [(ngModel)]="countdownPercent" [showValue]="false" [readonly]="true" ></p-knob>

                    <span class="timerDiv">{{ formatTime(countdown) }} </span>
                </div> -->
                <div class="actionDetails d-flex flex-column gap-4">
                    <div class="notifyHeader d-flex gap-2">
                        <span class="headerSpell">Delivery Partner Details</span>
                        <!-- <img src="../../assets/Screenshot (90).png" alt="../../assets/Screenshot (90).png"> -->
                    </div>
                    <div class="notifyOrder d-flex gap-4" *ngIf="order.deliveryPartnerDetails">
                        <div class="orderHeader d-flex flex-column gap-2">
                            <span class="orderSpell">Name</span>
                            <span class="orderSpell">Mobile Number</span>
                            <span class="orderSpell">Status</span>
                        </div>
                        <div class="infoDiv d-flex flex-column gap-2">
                            <span class="infoSpell"> : {{order.deliveryPartnerDetails.basicDetails.firstName + ' ' + order.deliveryPartnerDetails.basicDetails.lastName}}</span>
                            <span class="infoSpell"> : +91 {{order.deliveryPartnerDetails.basicDetails.mobileNumber}}</span>
                            <span>
                                <div *ngIf="order.isPickedUp">
                                    : Delivery Partner has picked up the order and is out for delivery.
                                </div>
                                <div *ngIf="!order.isPickedUp">
                                    : Delivery Partner is yet to pick up the order.
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="notifyOrder d-flex gap-5" *ngIf="!order.deliveryPartnerDetails">
                        <div class="orderHeader d-flex flex-column gap-2">
                            <span class="orderSpell">Delivery partner not assigned</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>