import { Component, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/toast.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  value: string = '';
  showForm: boolean = true;
  showPassword: boolean = false;
  showSpinner: boolean = false;
  isShrinking = true;
  isExpanding = false;
  registrationForm: FormGroup;

  constructor(private router: Router, private fb: FormBuilder, private firebaseAuth: AngularFireAuth,
    private toastService: ToastService, private authService: AuthService) {
    this.getScreenSize();
    this.registrationForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.checkPasswords
    }); // Custom validator for password matching
  }
  checkPasswords(formGroup: FormGroup) {
    const password = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { notSame: true };
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  navigateToLogin() {
    this.isShrinking = false;
    this.isExpanding = true;
    setTimeout(() => {
      this.router.navigateByUrl('login');
    }, 2500); // Duration of the shrink animation
  }

  initRegistration() {
    this.showSpinner = true;
    let oID = this.generateUniqueId();
    const email = this.registrationForm.value.email;
    const password = this.registrationForm.value.password;
    this.firebaseAuth.createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        console.log('User registered:', userCredential.user);
        var obj = {
          email: this.registrationForm.value.email,
          isPaymentDone: false,
          isRestaurantApproved: false,
          createdTimeStamp: Date.now(),
          openOutletApplications: [
            {
              currentStep: 2,
              applicationId: this.generateApplicationId(oID)
            }
          ],
          uid: userCredential.user?.uid,
          ownerId: oID
        }
        this.authService.registerOwner(userCredential.user?.uid, obj);
        this.showSpinner = false;
        this.isShrinking = false;
        this.isExpanding = true;
        this.toastService.showSuccess('User registered successfully')
        setTimeout(() => {
          this.router.navigateByUrl('onboard');
        }, 2500);
      })
      .catch((error) => {
        console.error('Registration error:', error);
        this.toastService.showError('Registration error:', error)
      });
  }

  generateUniqueId(): string {
    const currentTimestamp = Date.now();
    const lastSixDigits = parseInt(currentTimestamp.toString().slice(-6)); 
  
    const randomNumber = Math.floor(Math.random() * 100); 
  
    let uniqueId = lastSixDigits + randomNumber;
  
    // Ensure the result stays within 6 digits
    while (uniqueId >= 1000000) {
      uniqueId -= 1000000; 
    }
  
    const uniqueIdString = uniqueId.toString().padStart(6, '0');
  
    // Replace two digits with '30', ensuring the final length is 6
    let finalId = uniqueIdString;
    const replacementStartIndex = Math.floor(Math.random() * 4); // 0 to 3
    finalId = finalId.slice(0, replacementStartIndex) + '30' + finalId.slice(replacementStartIndex + 2); 
  
    return finalId;
  }

  generateApplicationId(oID: string): string {
    const currentTimestamp = Date.now();
    const timestampString = currentTimestamp.toString();
  
    // Get 6 random digits from the timestamp and concatenate them
    let sixDigitNumber = '';
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * timestampString.length);
      sixDigitNumber += timestampString.charAt(randomIndex);
    }
  
    // Parse oID and sixDigitNumber to integers
    const oIDNum = parseInt(oID, 10); 
    const sixDigitNum = parseInt(sixDigitNumber, 10);
  
    // Calculate the sum and ensure it's within 6 digits
    let finalId = oIDNum + sixDigitNum;
    while (finalId >= 1000000) {
      finalId -= 1000000; 
    }
  
    return 'OAP-' + finalId.toString().padStart(3, '0'); 
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
