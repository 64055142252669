import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { OnboardComponent } from './components/onboard/onboard.component';
import { LandingComponent } from './components/landing/landing.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { OrdersComponent } from './components/orders/orders.component';
import { MenuComponent } from './components/menu/menu.component';
import { HomeComponent } from './components/home/home.component';
import { OutletTimingsComponent } from './components/outlet-timings/outlet-timings.component';
import { PayoutsComponent } from './components/payouts/payouts.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'onboard',
    component: OnboardComponent
  },
  {
    path: 'landing',
    component: LandingComponent
  },
  {
    path: 'sidebar',
    component: SideBarComponent
  },
  {
    path: 'titlebar',
    component: TitleBarComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: 'outletTimings',
        component: OutletTimingsComponent
      },
      {
        path: 'menu',
        component: MenuComponent
      },
      {
        path: 'orders',
        component: OrdersComponent
      },
      {
        path: 'payouts',
        component: PayoutsComponent
      },
      {
        path: 'orderHistory',
        component: OrderHistoryComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
