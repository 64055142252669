import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  outletsCollection: AngularFirestoreCollection;

  constructor( private fireStore: AngularFirestore, private http: HttpClient, private authService: AuthService) {
    this.outletsCollection = fireStore.collection('outlets');
  }

  getMenuCategories() {
    return this.outletsCollection.doc(this.authService.outletDetails.location).collection(this.authService.outletDetails.locality).doc(this.authService.outletDetails.docId).collection('menu').get();
  }

  getMenuItems(categoryId: any) {
    return this.outletsCollection.doc(this.authService.outletDetails.location).collection(this.authService.outletDetails.locality).doc(this.authService.outletDetails.docId).collection('menu').doc(categoryId).collection('items').get();
  }

  addNewCategory(location: string, locality: string, docId: string, obj: any) {
    const menuCollection = this.outletsCollection.doc(location).collection(locality).doc(docId).collection('menu');
    const categoryName = obj.categoryName; // Get the categoryName from the object
    return menuCollection.ref.where('categoryName', '==', categoryName).get().then((querySnapshot: any) => {
      if (querySnapshot.size > 0) {
        return Promise.reject('Category with this name already exists!');
      } else {
        return menuCollection.get().toPromise().then((querySnapshot: any) => {
          const index = querySnapshot.size + 1;
          obj['index'] = index;
          return menuCollection.doc().set(obj);
        });
      }
    });
  }

  addNewItem(location: string, locality: string, docId: string, categoryId: any, obj: any) {
    return this.outletsCollection.doc(location).collection(locality).doc(docId).collection('menu').doc(categoryId).collection('items').doc().set(obj);
  }

  updateCategoryStatus(location: string, locality: string, docId: string, categoryId: string, newStatus: boolean): Promise<void> {
    const categoryDocRef = this.outletsCollection
      .doc(location)
      .collection(locality)
      .doc(docId)
      .collection('menu')
      .doc(categoryId);
  
    return categoryDocRef.update({ isActive: newStatus });
  }
}
