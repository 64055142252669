import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap, MapDirectionsRenderer, MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrl: './google-map.component.scss'
})
export class GoogleMapComponent {
  @Input() lat!: number;
  @Input() lng!: number;
  @Output() locationChanged = new EventEmitter<{ lat: number, lng: number }>();
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapMarker) marker!: any;

  mapOptions: google.maps.MapOptions = {
    zoom: 17,
    fullscreenControl: false,
    streetViewControl: false,
    disableDefaultUI: true,
    scrollwheel: false
  };

  markerOptions: google.maps.MarkerOptions = { draggable: false };

  ngAfterViewInit() { 
    if (this.lat && this.lng) {
      this.map.googleMap?.setCenter({ lat: this.lat, lng: this.lng }); // Use optional chaining
    }
    var ele = document.getElementsByClassName('map-container')[1] as HTMLElement
    console.warn(ele);
    ele.style.width = '100%'
    ele.style.borderRadius = '10px'
    ele.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 12px;'
  }

  onMapClick(event: any) {
    console.warn(event);
    
    this.marker.setPosition(event.latLng!); // Update marker position
    this.locationChanged.emit({
      lat: event.latLng!.lat(),
      lng: event.latLng!.lng(),
    });
  }

  onMarkerDragEnd(event: any) {
    console.warn(event);
    
    this.locationChanged.emit({ lat: event.latLng!.lat(), lng: event.latLng!.lng() });
  }
}
