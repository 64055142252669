import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap, MapDirectionsRenderer, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ToastService } from '../../shared/toast.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrl: './google-map.component.scss'
})
export class GoogleMapComponent {
  @Input() lat!: number;
  @Input() lng!: number;
  @Input() location!: string;
  @Input() locality!: string;
  @Output() locationChanged = new EventEmitter<{ lat: number, lng: number }>();
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapMarker) marker!: any;
  localityBounds: google.maps.LatLngBounds | undefined ;
  mapOptions: google.maps.MapOptions = {
    zoom: 17,
    fullscreenControl: false,
    streetViewControl: false,
    disableDefaultUI: false,
    scrollwheel: true
  };

  
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  
  constructor(private toastService: ToastService) {

  }

  getLocalityBounds() {
    const geocoder = new google.maps.Geocoder();
    const address = `${this.locality}, ${this.location}`; // Assuming you have localityName and cityName

    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        this.localityBounds = new google.maps.LatLngBounds(
          results[0].geometry.viewport.getSouthWest(),
          results[0].geometry.viewport.getNorthEast()
        );
        // console.warn(this.localityBounds);
        
      } else {
        console.error('Geocoder failed due to: ' + status);
        // Handle the error, e.g., show an error message or use default bounds
      }
    });
  }

  ngAfterViewInit() { 
    if(this.location) {
      this.getLocalityBounds();
    }
    if (this.lat && this.lng) {
      this.map.googleMap?.setCenter({ lat: this.lat, lng: this.lng });
    }
    var ele = document.getElementsByClassName('map-container')[1] as HTMLElement;
    ele.style.width = '100%';
    ele.style.borderRadius = '10px';
    ele.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 12px;';
    if(this.location) {
      var timer = setInterval(() => {
        if(this.localityBounds) {
          clearInterval(timer);
          this.applyLocalityStyle();
        }
      }, );
    }
  }

  applyLocalityStyle() {
    const map = this.map.googleMap;
    const northEast = this.localityBounds?.getNorthEast();
    const southWest = this.localityBounds?.getSouthWest();
    if (map && this.localityBounds) {
      const localityPolygon = new google.maps.Polygon({
        paths: [
          { lat: northEast?.lat(), lng: northEast?.lng() },
          { lat: northEast?.lat(), lng: southWest?.lng() },
          { lat: southWest?.lat(), lng: southWest?.lng() },
          { lat: southWest?.lat(), lng: northEast?.lng() },
          { lat: northEast?.lat(), lng: northEast?.lng() },
        ],
        strokeColor: "#FF0000", // Customize stroke color as needed
        strokeOpacity: 1.0,
        strokeWeight: 2,
        fillColor: "#000000", // Set fill color to transparent or black
        fillOpacity: 0.0, // Set fill opacity to 0
      });
      localityPolygon.setMap(map);
      google.maps.event.addListener(localityPolygon, 'click', (event: any) => {
        this.onMarkerDragEnd(event); // Call your onMapClick function
      });
    }
  }

  onMapClick(event: any) {
    const newLatLng = event.latLng;
  
    // Check if localityBounds is defined before using it
    if (this.localityBounds && this.localityBounds.contains(newLatLng)) {
      this.marker.setPosition(event.latLng!);
      this.locationChanged.emit({
        lat: event.latLng!.lat(),
        lng: event.latLng!.lng(),
      });
    } else {
      this.toastService.showError("Location is out of boundary")
      console.warn('out of boundary');
    }
  }

  onMarkerDragEnd(event: any) {
    const newLatLng = event.latLng;
    console.warn(this.localityBounds);
    
    if (this.localityBounds?.contains(newLatLng)) { 
      this.locationChanged.emit({ lat: event.latLng!.lat(), lng: event.latLng!.lng() });
    } else {
      this.toastService.showError("Location is out of boundary")
      console.warn('out of boundary');
    }
  }
}
