import { Component , HostListener} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/toast.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  value: string = '';
  showForm: boolean = true;
  showPassword: boolean = false;
  showSpinner: boolean = false;
  isShrinking = true;
  isExpanding = false;
  resetForm: FormGroup;
  isPasswordVisible: boolean = false;
  isPasswordShow: boolean = false;

  constructor(private router: Router, private fb: FormBuilder, private firebaseAuth: AngularFireAuth,
    private toastService: ToastService, private authService: AuthService) {
    this.getScreenSize();
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    },);
  }

  navigateToLogin() {
    this.isShrinking = false;
    this.isExpanding = true;
    setTimeout(() => {
      this.router.navigateByUrl('login');
    }, 2500);
  }

  initResetPassword() {
    if (this.resetForm.invalid) {
      this.toastService.showError('Invalid email address. Please provide a valid email.');
      return;
    }
    this.showSpinner = true;
    const email = this.resetForm.get('email')?.value;
    this.firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.showSpinner = false;
        this.toastService.showSuccess('Password reset email sent successfully. Please check your inbox.');
        this.navigateToLogin();
      })
      .catch((error) => {
        this.showSpinner = false;
        console.error('Password reset error:', error);
        this.toastService.showError('Failed to send password reset email. Please try again.');
      });
  }
  

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
