import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../../environments/environment';
import { LoginComponent } from './components/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './components/register/register.component';
import { HttpClientModule } from '@angular/common/http';
import { AadhaarSpacingDirective, AutoCapitalizeDirective, OnboardComponent } from './components/onboard/onboard.component';
import { StepperModule } from 'primeng/stepper';
import { VerticalStepperComponent } from './components/vertical-stepper/vertical-stepper.component';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputOtpModule } from 'primeng/inputotp';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AccordionModule } from 'primeng/accordion';
import { FileUploadModule } from 'primeng/fileupload';
import { RazorpayService } from './shared/razorpay.service';
import { LandingComponent } from './components/landing/landing.component';
import { CommonModule } from '@angular/common';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OrdersComponent } from './components/orders/orders.component';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { MenuComponent } from './components/menu/menu.component';
import { HomeComponent } from './components/home/home.component';
import { SpeedDialModule } from 'primeng/speeddial';
import { OutletTimingsComponent } from './components/outlet-timings/outlet-timings.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabMenuModule } from 'primeng/tabmenu';
import { KnobModule } from 'primeng/knob';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PayoutsComponent } from './components/payouts/payouts.component';
import { TableModule } from 'primeng/table';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { CalendarModule } from 'primeng/calendar';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChipsModule } from 'primeng/chips';
import { AutoCompleteModule } from 'primeng/autocomplete';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    OnboardComponent,
    VerticalStepperComponent,
    AadhaarSpacingDirective,
    SpinnerComponent,
    AutoCapitalizeDirective,
    GoogleMapComponent,
    LandingComponent,
    SideBarComponent,
    TitleBarComponent,
    OrdersComponent,
    MenuComponent,
    HomeComponent,
    OutletTimingsComponent,
    PayoutsComponent,
    OrderHistoryComponent,
    ForgotPasswordComponent

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ToastModule,
    FloatLabelModule,
    CheckboxModule,
    StepperModule,
    TooltipModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    InputOtpModule,
    DialogModule,
    MultiSelectModule,
    GoogleMapsModule,
    AccordionModule,
    FileUploadModule,
    CommonModule,
    InputSwitchModule,
    IconFieldModule,
    InputIconModule,
    SpeedDialModule,
    ConfirmDialogModule,
    DragDropModule,
    InputTextareaModule,
    TabMenuModule,
    KnobModule,
    OverlayPanelModule,
    TableModule,
    CalendarModule,
    ChipsModule,
    AutoCompleteModule
  ],

  providers: [RazorpayService, MessageService, ConfirmationService, provideAnimationsAsync()],
  bootstrap: [AppComponent]
})
export class AppModule { }
