<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer vw-100 d-flex" [style.height.px]="screenHeight">
    <div class="bgHolder" [ngClass]="{ 'shrink': isShrinking, 'expand': isExpanding }"></div>
    <div class="bgHolderDummy"></div>
    <div class="formDiv d-flex justify-content-center align-items-center">
        <div class="w-100 h-100 d-flex flex-column align-items-center justify-content-between formContainer">
            <div class="titleText d-flex justify-content-center align-items-center">
                <div class="d-flex align-items-baseline" style="gap: 3px;">
                    <span class="aahaarTitleOblong">AA</span>
                    <span class="aahaarTitle">haar</span>
                </div>
            </div>
            <div class="d-flex flex-column w-100">
                <div class="d-flex justify-content-center flex-column align-items-center">
                    <div class="welcomeText">
                        Welcome Onboard!
                    </div>
                    <div class="text-muted">Please fill in your details below</div>
                </div>
                <div class="w-100 d-flex flex-column gap-4">
                    <div class="w-100 d-flex flex-column">
                        <form [formGroup]="registrationForm" class="formHolder w-100 d-flex flex-column">
                            <p-floatLabel class="w-100">
                                <input class="bottom-border-input w-100" type="email" pInputText id="email"
                                    formControlName="email" />
                                <label for="email">Email</label>
                            </p-floatLabel>
                            <p-floatLabel class="w-100">
                                <input class="bottom-border-input w-100" type="password" pInputText id="password"
                                    formControlName="password" />
                                <label for="password">Password</label>
                                <i class="pi pi-eye" *ngIf="!showPassword" (click)="togglePasswordVisibility()"></i>
                                <i class="pi pi-eye-slash" *ngIf="showPassword"
                                    (click)="togglePasswordVisibility()"></i>
                            </p-floatLabel>
                            <p-floatLabel class="w-100">
                                <input class="bottom-border-input w-100" type="password" pInputText id="confirmPassword"
                                    formControlName="confirmPassword" />
                                <label for="password">Confirm Password</label>
                            </p-floatLabel>
                        </form>
                    </div>
                    <!-- <div class="formSubSection d-flex justify-content-between w-100">
                        <span class="d-flex gap-2">
                            <p-checkbox value="rememberMe" inputId="ny" />
                            <label for="ny">Remember Me</label>
                        </span>
                        <span>
                            <a href="">Forgot Password?</a>
                        </span>
                    </div> -->
                    <div class="formBtns w-100 d-flex flex-column gap-2 mt-4">
                        <span class="w-100">
                            <div class="custom-primeng-button" [ngClass]="{'disabled-custom-primeng-button': !registrationForm.valid}" (click)="initRegistration()">Register</div>
                        </span>
                        <!-- <div class="w-100 d-flex justify-content-center">
                            <span class="socialLoginBtn">
                                <button type="button" class="btn btn-light greyBtn"><img src="../../assets/images/googleIcon.png" alt=""> Sign in with Google</button>
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="formEndDiv d-flex gap-3">
                <span>Already have an account?</span>
                <span class="signUpBtn" (click)="navigateToLogin()">Sign In</span>
            </div>
        </div>
    </div>

</div>