import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrl: './payouts.component.scss'
})
export class PayoutsComponent {

  activeAction: string | null = null;
  payouts: any[] =[];
  // outlets: any[] =[];
  expandedRows: any = {};
  value: string = '';
  selectedDate: Date | null = null;
  locations: any[] = [];
  selectedLocation: any = null;
  activeItem: MenuItem | undefined;
  tabMenuItems: any[] = [];

  onActiveItemChange(event: any) {
    this.activeItem = event;
  }


  constructor(private activatedRoute: ActivatedRoute) {
    this.tabMenuItems = [
      // { label: 'Log History', icon: 'fa-solid fa-bowl-food' },
      { label: 'Active Payouts'},
      { label: 'Past Payouts'},
    ];
    this.activeItem = this.tabMenuItems[0]
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
    });

    this.payouts = [
      {
        // payoutPeriod: '25 nov 2024 - 1 dec 2024',
        // Orders: '20',
        // payoutDate: '2 dec 2024',
        // estimatedPayout: '₹ 4,350.15',
      },
    ];
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.payoutPeriod] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.payoutPeriod];
  }

  openInMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');   
  }

}


