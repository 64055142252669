import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, HostListener } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from '../../services/auth.service';
import { MenuService } from '../../services/menu.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ToastService } from '../../shared/toast.service';


interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  providers: [MessageService],
})
export class MenuComponent {

  checked: boolean = false;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  visible: boolean = false;
  filterVeg: boolean = false;
  filterNonVeg: boolean = false;
  categories: any[] = [];
  menuItems: any[] = [];
  filteredMenuItems: any;
  classifications: any[] = [];
  showAddCategory: boolean = false;
  updateCategory: boolean = false;
  updateItem: boolean = false;
  showAddItem: boolean = false;
  categoriesLoading: boolean = false;
  itemsLoading: boolean = false;
  addCategoryForm: FormGroup;
  addItemForm: FormGroup;
  outletsCollection: AngularFirestoreCollection;
  selectedCategory: any;
  imageSrc: any = '';
  file: any;
  selectedItem: any;

  constructor(private fireStore: AngularFirestore, private messageService: MessageService, private formBuilder: FormBuilder, 
    private authService: AuthService, private menuService: MenuService, private confirmationService: ConfirmationService, 
    private fireStorage: AngularFireStorage, private toastService: ToastService) {
    this.outletsCollection = fireStore.collection('outlets');
    this.getScreenSize();
    this.addCategoryForm = this.formBuilder.group({
      categoryName: ['', Validators.required],
    });
    this.addItemForm = this.formBuilder.group({  
      itemName: ['', Validators.required],
      itemClassification: ['', Validators.required],
      itemDescription: [''],
      itemPrice: [null, [Validators.required, this.minPriceValidator(4)]],
      inStock: [false],
    });
    this.classifications = [
      { name: 'Veg' },
      { name: 'Non-Veg' },
    ]
    let startTime = new Date();
    const timer = setInterval(() => {
      if (this.authService.outletDetails) {
        clearInterval(timer);
        this.refreshMenuCategories();
      } else {
        let elapsedTime = new Date().getTime() - startTime.getTime(); 
        if (elapsedTime > 60000) {
          clearInterval(timer);
        }
      }
    },);
  }

  minPriceValidator(min: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value !== null && value <= min) {
        return { minPrice: true };
      }
      return null;
    };
  }

  ngOnInit() {
  }

  refreshMenuCategories() {
    this.categoriesLoading = true;
    this.categories = [];
    this.menuService.getMenuCategories().subscribe((res: any) => {
      res.forEach((menuCategory: any) => {
        const data = menuCategory.data();
        const id = menuCategory.id;
        this.categories.push({ ...data, id });
      });
      this.categories.sort((a, b) => a.index - b.index);
      this.categoriesLoading = false;
      if(this.categories.length == 0) {
        this.itemsLoading = false;
        this.filteredMenuItems = [];
      } else {
        if(!this.selectedCategory) {
          this.selectedCategory = this.categories[0];
          this.loadMenuItems();
        }
      }
    })
  }

  loadMenuItems() {
    this.itemsLoading = true;
    this.menuItems = [];
    this.menuService.getMenuItems(this.selectedCategory.id).subscribe((res: any) => {
      res.forEach((menuItem: any) => {
        const data = menuItem.data();
        const id = menuItem.id
        this.menuItems.push({...data, id});
      })
      this.filterMenuItems()
      this.itemsLoading = false;
    }, err => {
      console.warn(err);
      
    })
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
  
    // Update the index values in Firestore
    this.categories.forEach((category, index) => {
      const newIndex = index + 1; // Calculate the new index
      const docRef = this.outletsCollection
        .doc(this.authService.outletDetails.location)
        .collection(this.authService.outletDetails.locality)
        .doc(this.authService.outletDetails.docId)
        .collection('menu')
        .doc(category.id); // Get the document reference using the ID
  
      docRef.update({ index: newIndex })
        .then(() => console.log(`Updated index for ${category.categoryName} to ${newIndex}`))
        .catch((error: any) => console.error("Error updating index:", error));
    });
  }

  showDialog() {
    this.visible = true;
    console.log(this.visible);
  }

  openAddCategoryModal(category?: any) {
    this.showAddCategory = true;
    if (category) {
      this.updateCategory = true;
      this.addCategoryForm.patchValue({
        categoryName: category.categoryName,
      });
    }
  }

  closeAddCategoryModal() {
    this.addCategoryForm.reset();
    this.showAddCategory = false;
    this.updateCategory = false;
  }

  openAddItemModal(menuItem?: any) {
    this.showAddItem = true;
    if (menuItem) {
      console.warn(menuItem);
      this.updateItem = true;
      this.selectedItem = menuItem;
      this.addItemForm.patchValue({
        itemName: menuItem.itemName,
        itemClassification: {name: menuItem.itemClassification},
        itemDescription: menuItem.itemDescription,
        itemPrice: menuItem.itemPrice,
        inStock: menuItem.inStock,
      });
      if(menuItem.itemImageUrl) {
        this.imageSrc = menuItem.itemImageUrl
      } else {
        this.imageSrc = null
      }
    }
  }

  closeAddItemModal() {
    this.showAddItem = false;
    this.updateItem = false;
    this.addItemForm.reset();
    this.imageSrc = null
  }

  saveCategory() {
    var obj = {}
    if(this.updateCategory) {
      obj = {
        categoryName: this.addCategoryForm.value.categoryName,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.authService.userDetails.uid,
        lastUpdatedBy: this.authService.userDetails.userName,
      }
      this.menuService.addNewCategory(this.authService.outletDetails.location, this.authService.outletDetails.locality, this.authService.outletDetails.docId, obj, this.updateCategory, this.selectedCategory.id).then((res) => {
        this.toastService.showSuccess(`Category ${this.updateItem ? 'updated' : 'added'} successfully`)
        this.closeAddCategoryModal();
        this.refreshMenuCategories();
      }, err => {
        console.warn("Error", err);
      })
    } else {
      obj = {
        categoryName: this.addCategoryForm.value.categoryName,
        createdAt: new Date().getTime(),
        createdById: this.authService.userDetails.uid,
        createdBy: this.authService.userDetails.userName,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.authService.userDetails.uid,
        lastUpdatedBy: this.authService.userDetails.userName,
        isActive: true
      }
      this.menuService.addNewCategory(this.authService.outletDetails.location, this.authService.outletDetails.locality, this.authService.outletDetails.docId, obj, this.updateCategory).then((res) => {
        this.toastService.showSuccess(`Category ${this.updateItem ? 'updated' : 'added'} successfully`)
        this.closeAddCategoryModal();
        this.refreshMenuCategories();
      }, err => {
        console.warn("Error", err);
      })
    }

  }

  async saveItem() {
    var imgUrl = '';
    if(this.file) {
      const path = `outlets/${this.authService.outletDetails.location}/${this.authService.outletDetails.locality}/${this.authService.outletDetails.docId}/${this.addItemForm.controls['itemName'].value}/previewImg`
      const uploadTask = this.fireStorage.upload(path, this.file)
      const url = (await (await uploadTask).ref.getDownloadURL()).valueOf()
      var timer = setInterval(() => {
        if(url.toString() != '') {
          imgUrl = url;
          var obj = {};
          if(this.updateItem) {
            obj = {
              itemName: this.addItemForm.controls['itemName'].value,
              itemClassification: this.addItemForm.controls['itemClassification'].value.name,
              itemDescription: this.addItemForm.controls['itemDescription'].value == '' ? null : this.addItemForm.controls['itemDescription'].value,
              itemPrice: this.addItemForm.controls['itemPrice'].value,
              itemImageUrl: imgUrl,
              lastUpdatedAt: new Date().getTime(),
              lastUpdatedById: this.authService.userDetails.uid,
              lastUpdatedBy: this.authService.userDetails.userName,
            }
            this.menuService.addNewItem(this.authService.outletDetails.location, this.authService.outletDetails.locality, this.authService.outletDetails.docId, this.selectedCategory.id, obj, this.updateItem, this.selectedItem.id).then((res: any) => {
              this.addItemForm.reset()
              this.toastService.showSuccess(`Item ${this.updateItem ? 'updated' : 'added'} successfully`)
              this.closeAddItemModal()
              this.file = null
              this.imageSrc = ''
              this.updateSelectedCategory(this.selectedCategory)
            }, (err: any) => {
              console.warn(err);
              this.toastService.showError("Error adding item")
              this.closeAddItemModal()
              this.file = null
              this.imageSrc = ''
            })
          } else {
            obj = {
              itemName: this.addItemForm.controls['itemName'].value,
              itemClassification: this.addItemForm.controls['itemClassification'].value.name,
              itemDescription: this.addItemForm.controls['itemDescription'].value == '' ? null : this.addItemForm.controls['itemDescription'].value,
              itemPrice: this.addItemForm.controls['itemPrice'].value,
              itemImageUrl: imgUrl,
              createdAt: new Date().getTime(),
              createdById: this.authService.userDetails.uid,
              createdBy: this.authService.userDetails.userName,
              lastUpdatedAt: new Date().getTime(),
              lastUpdatedById: this.authService.userDetails.uid,
              lastUpdatedBy: this.authService.userDetails.userName,
            }
            this.menuService.addNewItem(this.authService.outletDetails.location, this.authService.outletDetails.locality, this.authService.outletDetails.docId, this.selectedCategory.id, obj, this.updateItem).then((res: any) => {
              this.addItemForm.reset()
              this.toastService.showSuccess(`Item ${this.updateItem ? 'updated' : 'added'} successfully`)
              this.closeAddItemModal()
              this.file = null
              this.imageSrc = ''
              this.updateSelectedCategory(this.selectedCategory)
            }, (err: any) => {
              console.warn(err);
              this.toastService.showError("Error adding item")
              this.closeAddItemModal()
              this.file = null
              this.imageSrc = ''
            })
          }

          clearInterval(timer)
        }
      }, 100)
    } else {
      var obj1 = {}
      if(this.updateItem) {
        obj1 = {
          itemName: this.addItemForm.controls['itemName'].value,
          itemClassification: this.addItemForm.controls['itemClassification'].value.name,
          itemDescription: this.addItemForm.controls['itemDescription'].value == '' ? null : this.addItemForm.controls['itemDescription'].value,
          itemPrice: this.addItemForm.controls['itemPrice'].value,
          itemImageUrl: null,
          lastUpdatedAt: new Date().getTime(),
          lastUpdatedById: this.authService.userDetails.uid,
          lastUpdatedBy: this.authService.userDetails.userName,
        }
        this.menuService.addNewItem(this.authService.outletDetails.location, this.authService.outletDetails.locality, this.authService.outletDetails.docId, this.selectedCategory.id, obj1, this.updateItem, this.selectedItem.id).then((res: any) => {
          this.addItemForm.reset()
          this.toastService.showSuccess(`Item ${this.updateItem ? 'updated' : 'added'} successfully`)
          this.closeAddItemModal()
          this.updateSelectedCategory(this.selectedCategory)
        }, (err: any) => {
          console.warn(err);
          this.toastService.showError("Error adding item")
          this.closeAddItemModal()
        })
      } else {
        obj1 = {
          itemName: this.addItemForm.controls['itemName'].value,
          itemClassification: this.addItemForm.controls['itemClassification'].value.name,
          itemDescription: this.addItemForm.controls['itemDescription'].value == '' ? null : this.addItemForm.controls['itemDescription'].value,
          itemPrice: this.addItemForm.controls['itemPrice'].value,
          itemImageUrl: null,
          createdAt: new Date().getTime(),
          createdById: this.authService.userDetails.uid,
          createdBy: this.authService.userDetails.userName,
          lastUpdatedAt: new Date().getTime(),
          lastUpdatedById: this.authService.userDetails.uid,
          lastUpdatedBy: this.authService.userDetails.userName,
        }
        this.menuService.addNewItem(this.authService.outletDetails.location, this.authService.outletDetails.locality, this.authService.outletDetails.docId, this.selectedCategory.id, obj1, this.updateItem).then((res: any) => {
          this.addItemForm.reset()
          this.toastService.showSuccess(`Item ${this.updateItem ? 'updated' : 'added'} successfully`)
          this.closeAddItemModal()
          this.updateSelectedCategory(this.selectedCategory)
        }, (err: any) => {
          console.warn(err);
          this.toastService.showError("Error adding item")
          this.closeAddItemModal()
        })
      }

    }
  }

  async readURL(event: any) {
    if (event.target.files && event.target.files[0]) {
        this.file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => this.imageSrc = reader.result;
        reader.readAsDataURL(this.file);
    }
  }

  removeImg() {
    this.file = null
    this.imageSrc = ''
  }

  showConfirmDialog(category: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to change the active status of ${category.categoryName}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Proceed',
      rejectLabel: 'Cancel',
      accept: () => {
        this.menuService.updateCategoryStatus(
          this.authService.outletDetails.location,
          this.authService.outletDetails.locality,
          this.authService.outletDetails.docId,
          category.id, 
          category.isActive
        )
        .then(() => {
          console.log('Category status updated successfully!');
          this.refreshMenuCategories();
          category.isActive = !category.isActive; // Update the local isActive after successful update
        })
        .catch((error: any) => console.error("Error updating category status:", error));
      },
      reject: () => {
        setTimeout(() => {
          category.isActive = !category.isActive;
        });
      }
    });
  }

  showConfirmDialogItem(menuItem: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to change the active status of ${menuItem.itemName}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Proceed',
      rejectLabel: 'Cancel',
      accept: () => {
        this.menuService.updateItemStatus(
          this.authService.outletDetails.location,
          this.authService.outletDetails.locality,
          this.authService.outletDetails.docId,
          this.selectedCategory.id, 
          menuItem.isActive,
          menuItem.id
        )
        .then(() => {
          this.loadMenuItems();
          menuItem.isActive = !menuItem.isActive; // Update the local isActive after successful update
        })
        .catch((error: any) => console.error("Error updating item status:", error));
      },
      reject: () => {
        setTimeout(() => {
          menuItem.isActive = !menuItem.isActive;
        });
      }
    });
  }

  updateSelectedCategory(category: any) {
    this.selectedCategory = category;
    this.loadMenuItems();
  }

  onDelete(category: any) {
    this.menuService.deleteCategory(category.id).then((res) => {
      console.warn('category deleted successfully');
      this.refreshMenuCategories();
      this.selectedCategory = this.categories[0];
    }, err => {
      console.warn("Error", err);
    })
  }

  deleteItem(menuItem: any) {
    this.menuService.deleteItem(this.selectedCategory.id, menuItem.id).then((res) => {
      console.warn('item deleted successfully');
      this.loadMenuItems();
    }, err => {
      console.warn("Error", err);
    })
  }

  filterMenuItems() {
    this.filteredMenuItems = this.menuItems.filter(item => {
      const isVeg = item.itemClassification === 'Veg';
      const isNonVeg = item.itemClassification === 'Non-Veg';
  
      // Apply filter based on switch states
      if (this.filterVeg && this.filterNonVeg) {
        return isVeg || isNonVeg; // Show both Veg and Non-Veg
      } else if (this.filterVeg) {
        return isVeg; // Show only Veg
      } else if (this.filterNonVeg) {
        return isNonVeg; // Show only Non-Veg
      } else {
        return true; // Show all items
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    let safeAreaInsetTop = 0;
    let safeAreaInsetBottom = 0;
    let safeAreaInsetLeft = 0;
    let safeAreaInsetRight = 0;

    if (window.visualViewport) {
      const visualViewport = window.visualViewport;

      safeAreaInsetTop = visualViewport.offsetTop || 0;
      safeAreaInsetBottom = (window.innerHeight - (visualViewport.offsetTop + visualViewport.height)) || 0;

      safeAreaInsetLeft = visualViewport.offsetLeft || 0;
      safeAreaInsetRight = (window.innerWidth - (visualViewport.offsetLeft + visualViewport.width)) || 0;
    }

    if (safeAreaInsetTop > 0 || safeAreaInsetBottom > 0 || safeAreaInsetLeft > 0 || safeAreaInsetRight > 0) {
      this.screenHeight -= (safeAreaInsetTop + safeAreaInsetBottom);
      this.screenWidth -= (safeAreaInsetLeft + safeAreaInsetRight);
    }

    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
