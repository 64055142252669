import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  @ViewChild('bgHolder') bgHolder!: ElementRef;

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  email: string = '';
  password: string = '';
  showForm: boolean = true;
  showPassword: boolean = false;
  isShrinking = true;
  isExpanding = false;

  constructor(private router: Router, private firebaseAuth: AngularFireAuth) {
    this.getScreenSize();
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  navigateToRegister() {
    this.isShrinking = false;
    this.isExpanding = true;
    setTimeout(() => {
      this.router.navigateByUrl('register');
    }, 2500); // Duration of the shrink animation
  }

  initLoginPartner() {
  
    this.firebaseAuth.signInWithEmailAndPassword(this.email, this.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log('User signed in:', user);
        this.router.navigateByUrl('home');
        // ... now you can redirect to the partner dashboard or perform other actions
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('Error signing in:', errorCode, errorMessage);   
  
        // ... display an error message to the user
      });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

}
