import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { merge, Observable, switchMap } from 'rxjs';
import firebase from 'firebase/compat/app';
@Injectable({
  providedIn: 'root'
})
export class OnboardService {

  restaurantOwnersCollection: AngularFirestoreCollection;
  eKYCurl: string = 'https://api.quickekyc.com/api/v1/';
  eKYCApiToken: string = 'b1a41d91-c390-455f-9c9d-7c6fdcf7fc89';

  constructor( private fireStore: AngularFirestore, private http: HttpClient) {
    this.restaurantOwnersCollection = fireStore.collection('restaurantOwners');
  }

  getOnboardingDetails(uid: string) {
    return this.restaurantOwnersCollection.doc(uid).get();
  }

  async updateOnBoardingProcess(activeStep: number, uid: string, obj?: any, index?: any, action?: string) {
    const docRef: AngularFirestoreDocument<any> = this.restaurantOwnersCollection.doc(uid);
    const docSnapshot = await docRef.get().toPromise();
    const currentOnboardingStatus = docSnapshot?.data() || {};

    // Update Logic: Increment currentStep
    let updateData: Partial<any> = {
      openOutletApplications: currentOnboardingStatus.openOutletApplications.map((application: any, i: any) => 
        i === index ? { 
          ...application, 
          currentStep: activeStep == 7 ? application.currentStep : application.currentStep + 1,
        } : application
      )
    };


    if (activeStep === 2) {
      // updateData['onBoardingStatus'].ownerDetails = obj; // Update userDetails
      updateData['mobile'] = obj.mobile; // Assuming you also want to set mobile on the first step
      updateData['firstName'] = obj.firstName; // Assuming you also want to set mobile on the first step
      updateData['lastName'] = obj.lastName; // Assuming you also want to set mobile on the first step
    } else if (activeStep === 3) {
      // updateData['onBoardingStatus'].userVerification = obj; // Update userVerification
    } else if (activeStep === 4) {
      // updateData['onBoardingStatus'].restaurantDetails = obj; 
      updateData = {
        openOutletApplications: currentOnboardingStatus.openOutletApplications.map((application: any, i: any) => 
          i === index ? { 
            ...application, 
            currentStep: application.currentStep + 1,
            outletDetails: obj
          } : application
        )
      };
    } else if (activeStep === 5) {
      if(action == 'gst') {
        updateData = {
          openOutletApplications: currentOnboardingStatus.openOutletApplications.map((application: any, i: any) => 
            i === index ? { 
              ...application, 
              isGSTSubmitted: true,
              gstData: obj
            } : application
          )
        };
      } else if(action == 'fssai') {
        updateData = {
          openOutletApplications: currentOnboardingStatus.openOutletApplications.map((application: any, i: any) => 
            i === index ? { 
              ...application, 
              isFSSAISubmitted: true,
              fssaiData: obj
            } : application
          )
        };
      } else if(action == 'udyam') {
        updateData = {
          openOutletApplications: currentOnboardingStatus.openOutletApplications.map((application: any, i: any) => 
            i === index ? { 
              ...application, 
              isUdyamSubmitted: true,
              udyamData: obj
            } : application
          )
        };
      } else if(action == 'all') {
        updateData = {
          openOutletApplications: currentOnboardingStatus.openOutletApplications.map((application: any, i: any) => 
            i === index ? { 
              ...application, 
              currentStep: application.currentStep + 1,
            } : application
          )
        };
      }
      // updateData['onBoardingStatus'].restaurantVerification = obj; // Update userVerification
    } else if (activeStep === 6) {
      updateData['isPaymentDone'] = true; // Update userVerification
    }

    try {
      await docRef.update(updateData);
      console.log('Document successfully updated!');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  }

  // sendAadhaarOtp(userAadhaar: string) {
  //   const body = {
  //     key: this.eKYCApiToken,
  //     id_number: userAadhaar
  //   };

  //   const httpOptions = {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  //   };

  //   return this.http.post(this.eKYCurl+'aadhaar-v2/generate-otp', body, httpOptions); // Return the Observable from the POST request
  // }

  sendAadhaarOtp(userAadhaar: string) {
    const body = {
      key: this.eKYCApiToken,
      id_number: userAadhaar,
    };
    const functionUrl = 'https://asia-south1-aahaar-qa.cloudfunctions.net/eKYCProxy';
    return this.http.post(functionUrl, body, { responseType: 'json' });
  }

  sendPanOtp(userPAN: string) {
    const body = {
      key: this.eKYCApiToken,
      id_number: userPAN,
      action: 'PAN'
    };
    const functionUrl = 'https://asia-south1-aahaar-qa.cloudfunctions.net/eKYCProxy';
    return this.http.post(functionUrl, body, { responseType: 'json' });
  }

  verifyBankAcc(bankAcc: string, ifscCode: string) {
    const body = {
      key: this.eKYCApiToken,
      id_number: bankAcc,
      ifsc: ifscCode,
      action: 'BANK'
    };
    const functionUrl = 'https://asia-south1-aahaar-qa.cloudfunctions.net/eKYCProxy';
    return this.http.post(functionUrl, body, { responseType: 'json' });
  }

  verifyAadhaarOtp(requestId: string, otp: string) {
    const body = {
      key: this.eKYCApiToken,
      requestId: requestId.toString(),
      otp: otp,
    };
    const functionUrl = 'https://asia-south1-aahaar-qa.cloudfunctions.net/eKYCProxy';
    return this.http.post(functionUrl, body, { responseType: 'json' });
  }

  saveAadhaarData(uid: string, obj: any) {
    return this.restaurantOwnersCollection.doc(uid).set(
      {
        aadhaarData: obj.data,
        isAadhaarVerified: true
      },
      {
        merge: true
      }
    )
  }

  savePanData(uid: string, obj: any) {
    return this.restaurantOwnersCollection.doc(uid).set(
      {
        panData: obj.data,
        isPanVerified: true
      },
      {
        merge: true
      }
    )
  }

  saveBankAccData(uid: string, obj: any) {
    return this.restaurantOwnersCollection.doc(uid).set(
      {
        primaryBankAccData: obj.data,
        isBankVerified: true
      },
      {
        merge: true
      }
    )
  }

  demo() {
    const body = { message: 'Hello World' };
  
    return this.http.post('https://asia-south1-aahaar-qa.cloudfunctions.net/helloWorld', body, {
      responseType: 'text'
    })
  }

  addNewApplication(uid: string, obj: any) {
    return this.restaurantOwnersCollection.doc(uid).set({
      openOutletApplications: firebase.firestore.FieldValue.arrayUnion(obj)
    }, { merge: true });
  }
  

  sendEmail(to: string[], subject: string, templatePath: string): Observable<any> { // Pass template path
    // Read the HTML template file
    return this.http.get(templatePath, { responseType: 'text' })
      .pipe(
        switchMap((htmlContent) => { // Use switchMap to chain requests
          const body = {
            to,
            subject,
            html: htmlContent // Include HTML content in the body
          };

          const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
          };

          return this.http.post('https://asia-south1-aahaar-qa.cloudfunctions.net/sendEmail', body, httpOptions);
        })
      );
  }
}
