import { Component , Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ConfirmationService } from 'primeng/api';
import { ToastService } from '../../shared/toast.service';

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrl: './title-bar.component.scss'
})
export class TitleBarComponent {

  isOnline: boolean = false;
  outletDetails: any;
  userDetails: any;
  @Input() activeNav: string = '';
  activeAction: string | null = null;

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService, private confirmationService: ConfirmationService, private toastService: ToastService) {
    let startTime = new Date(); // Capture the start time
    const timer = setInterval(() => {
      if (this.authService.outletDetails) {
        clearInterval(timer);
        this.outletDetails = this.authService.outletDetails;
        this.userDetails = this.authService.userDetails;
        this.getStatus();
      } else {
        // Calculate elapsed time in milliseconds
        let elapsedTime = new Date().getTime() - startTime.getTime(); 
  
        // If elapsed time exceeds 1 minute (60000 milliseconds), clear the interval
        if (elapsedTime > 60000) {
          clearInterval(timer);
        }
      }
    },);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
    });
  }

  getStatus() {
    this.authService.getOnlineStatus(this.authService.outletDetails.location, this.authService.outletDetails.locality, this.authService.outletDetails.docId).subscribe((res: any) => {
      this.isOnline = res.data().isOnline;
    })
  }

  showConfirmDialog() {
    this.confirmationService.confirm({
      message: `Are you sure you want to go ${this.isOnline ? 'Online' : 'Offline'}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.updateStatus()
      },
      reject: () => {
        // If the user rejects, revert the input switch 
        this.isOnline = !this.isOnline; 
      }
    });
  }

  updateStatus() {
    this.authService.updateOnlineStatus(this.authService.outletDetails.location, this.authService.outletDetails.locality, this.authService.outletDetails.docId, this.isOnline).then((res: any) => {
      // console.warn(res);
      this.toastService.showSuccess(`Outlet went ${this.isOnline ? 'Online' : 'Offline'} successfully`)
    }, err => {
      this.toastService.showError(`Error turning ${this.isOnline ? 'on' : 'off'} outlet`)
      // console.warn(err);
    })
  }
}
