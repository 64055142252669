<div class="navContainer">
    <!-- <p-accordion class="navAccordion" [activeIndex]="activeIndex">
        <p-accordionTab class="navItem" *ngFor="let navItem of navItems; let i = index">
            <ng-template pTemplate="header">
                <div class="navItemHeader d-flex w-100 justify-content-between align-items-center"
                (click)="updateRoute(navItem?.name)"
                    [ngClass]="{ 'activeNavItem': isNavItemActive(navItem.name) }">
                    <div class="d-flex align-items-center">
                        <span [class]="navItem.icon + ' navItemIcon'"></span>
                        <span class="navItemTitle">
                            {{ navItem.name }}
                        </span>
                    </div>
                    <i class="pi pi-chevron-down expandIcon" *ngIf="navItem.isExpandable"
                        [ngClass]="{'rotate': activeIndices[navItem.name]}"></i>
                </div>
            </ng-template>
        </p-accordionTab>
    </p-accordion> -->
    <div class="navAccordion">
        <div class="navItem" *ngFor="let navItem of navItems; let i = index">
          <div class="navItemHeader d-flex w-100 justify-content-between align-items-center"
               (click)="updateRoute(navItem?.name)"
               [ngClass]="{ 'activeNavItem': isNavItemActive(navItem.name) }">
            <div class="d-flex align-items-center">
              <span [class]="navItem.icon + ' navItemIcon'"></span>
              <span class="navItemTitle">
                {{ navItem.name }}
              </span>
            </div>
          </div>    
        </div>
      </div>
      
    <div class="bottomCircle speeddial-linear-demo">
        <p-speedDial [model]="items" direction="up" [radius]="70" showIcon="pi pi-cog" hideIcon="pi pi-cog"
            type="semi-circle" />
    </div>
</div>