import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  restaurantOwnersCollection: AngularFirestoreCollection;
  outletsCollection: AngularFirestoreCollection;
  staffCollection: AngularFirestoreCollection;
  outletDetails: any;
  userDetails: any;
  superAdminIds: any[] = ['1J9oC4lb2PMXG6oPCPNyS5fqhN63', 'zmDzhMy8HrheNFwvGrdLvLyls8w1', 'gRtFyvPwpFVD0nEfAhXKySAq5Ul1']

  constructor( private fireStore: AngularFirestore, private http: HttpClient) {
    this.restaurantOwnersCollection = fireStore.collection('restaurantOwners');
    this.outletsCollection = fireStore.collection('outlets');
    this.staffCollection = fireStore.collection('staff');
  }

  registerOwner(uid: string | undefined, obj: any): Promise<void> {
    if (!uid) {
      return Promise.reject('UID is undefined');
    }
    return this.restaurantOwnersCollection.doc(uid).set(obj)
      .then(() => {
        console.log('Restaurant owner registered successfully');
      })
      .catch((error) => {
        console.error('Error registering restaurant owner: ', error);
        throw error;
      });
  }

  getOutletDetails(uid: string) {
    return this.restaurantOwnersCollection.doc(uid).get();
  }

  copyDeliveryPartnerData(sourceDocId: string, targetDocId: string) {
    const sourceDocRef = this.fireStore.collection('restaurantOwners').doc(sourceDocId);
    const targetDocRef = this.fireStore.collection('restaurantOwners').doc(targetDocId);
  
    // Use a query to get the source document
    return this.fireStore.collection('restaurantOwners', ref =>
      ref.where(firebase.firestore.FieldPath.documentId(), '==', sourceDocId) 
    ).get().toPromise().then((querySnapshot: any) => {
      if (querySnapshot.size > 0) {
        const doc = querySnapshot.docs[0]; 
        const data = doc.data();
        return targetDocRef.set(data);
      } else {
        console.error("Source document doesn't exist!");
        return Promise.reject("Source document doesn't exist!");
      }
    });
  }

  getOwnerDetails(uid: string) {
    return this.restaurantOwnersCollection.doc
  }

  getOnlineStatus(location: string, locality: string, docId: string) {
    return this.outletsCollection.doc(location).collection(locality).doc(docId).get();
  }

  updateOnlineStatus(location: string, locality: string, docId: string, status: any) {
    return this.outletsCollection.doc(location).collection(locality).doc(docId).update({isOnline: status});
  }

  sendNotification(uid: any, obj: any) {
    return this.staffCollection.doc(uid).collection('notifications').add(obj);
  }
}
